import { Box, TextField } from '@mui/material';
import InfoFaturamento from '../Dashboard/components/InfoFaturamento';
import Profile from '../Dashboard/components/Profile';
import Tools from '../Dashboard/components/Tools';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import api from '../generalComponents/api';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function Faq() {
  const navigate = useNavigate();
  const [pergunta, setPergunta] = useState('');
  const [resposta, setResposta] = useState('');
  const [perguntas, setPerguntas] = useState([] as Array<any>);
  const [autorizacao, setAutorizacao] = useState(false);
  const [userRole, setUserRole] = useState('');
  // controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    const getFaq = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/client/getAccountConfig', { params: { dominio: domain } })
        .then((response) => {
          setPerguntas(response.data.perguntas);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getFaq();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: "" };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  const handleSubmitNovaFaq = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (pergunta.trim() === '' || resposta.trim() === '') {
      alert('Título e pergunta são obrigatórios!');
      return;
    }
    const novaFaq = {
      pergunta: pergunta,
      resposta: resposta,
    };
    setPerguntas([...perguntas, novaFaq]);
    setPergunta('');
    setResposta('');
  };

  const removeFaq = (index: number) => {
    setPerguntas((prevFaq) => prevFaq.filter((_, i) => i !== index));
  };

  const saveChanges = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio: domain,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
      perguntas,
    };

    await api
      .put('/client/updateFAQ', data)
      .then((response) => {
        location.reload();
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="perguntas"
                nivel={'1'}
                role={userRole}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="perguntas" role={userRole} />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl w-full text-center">
                  Perguntas Frequentes
                </h1>
                <div>
                  <div className="flex flex-col p-2 w-full border rounded-xl my-2">
                    <h1 className="montserrat-500">
                      Definir nova pergunta frequente
                    </h1>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '98%' },
                        width: '100%',
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Pergunta"
                        variant="outlined"
                        color="success"
                        value={pergunta}
                        onChange={(e) => setPergunta(e.target.value)}
                      />
                    </Box>
                    <div className="flex flex-row w-full">
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { mx: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Resposta"
                          variant="outlined"
                          color="success"
                          value={resposta}
                          onChange={(e) => setResposta(e.target.value)}
                        />
                      </Box>
                      <button
                        onClick={(e) => handleSubmitNovaFaq(e)}
                        className="bg-green-500 text-white px-4 rounded-xl mx-1 hover:bg-green-600 transition-all duration-200 ease-in-out"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col w-full my-2">
                    {perguntas[0] == undefined ? (
                      <></>
                    ) : (
                      <>
                        {perguntas?.map((pergunta, index) => (
                          <div
                            key={index}
                            className="flex flex-col p-2 w-full border rounded-xl my-1 shadow-md bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out cursor-pointer"
                          >
                            <div className="relative w-full">
                              <h1 className="montserrat-500">
                                P: {pergunta?.pergunta}
                              </h1>
                              <h1 className="montserrat-400">
                                R: {pergunta?.resposta}
                              </h1>
                              <button
                                className="absolute bottom-6 right-0 text-red-500 font-bold text-3xl h-6 w-6 flex items-center text-center justify-center"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFaq(index);
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 transition-all duration-200 ease-in-out"
                    onClick={(e) => saveChanges()}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
