"use client"
import { useEffect } from "react"

export default function Session() {
    useEffect(() => {
        if (typeof document === 'undefined' || typeof window === 'undefined') {
            return;
        }
        localStorage.setItem('appSession', new URLSearchParams(window.location.search).get('token') || "")
        location.href = '/'
    }, [])

    return (
        <></>
    )
}
