import { Box, TextField } from '@mui/material';
import HeaderMain from '../generalComponents/HeaderMain';
import { useEffect, useState } from 'react';
import { Masks } from '../Masks/masks';
import { jwtDecode } from 'jwt-decode';
import api from '../generalComponents/api';
import Autorizacao from '../generalComponents/Autorizacao';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function TitulosPremiados() {
  const masks = new Masks();
  const navigate = useNavigate();

  const [titulosPremiados, setTitulosPremiados] = useState([] as any);
  const [controleTitulos, setControleTitulos] = useState(false);
  const [premioNovotitulo, setPremioNovoTitulo] = useState('');
  const [numeroNovoTitulo, setNumeroNovoTitulo] = useState('');
  const [raffleSize, setRaffleSize] = useState(0);
  const [autorizacao, setAutorizacao] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [link, setLink] = useState('');
  //buscar pedido pelo número do título
  const [cotaSearch, setCotaSearch] = useState('');
  const [enableSearchButton, setEnableSearchButton] = useState(true);
  //states auxiliares
  const [enableSaveButton, setEnableSaveButton] = useState(true);

  const handleSubmitTitulosPremiados = (
    e: React.FormEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    const obj: any = {};
    obj.titulo = masks.setZeroLeft(
      numeroNovoTitulo,
      (raffleSize - 1).toString().length,
    );
    obj.nome = premioNovotitulo;
    obj.status = 'liberado';
    if (premioNovotitulo.trim() !== '' && numeroNovoTitulo.trim() !== '') {
      titulosPremiados.push(obj);
      setPremioNovoTitulo('');
      setNumeroNovoTitulo('');
    }
  };

  const handleToggleStatus = (index: any) => {
    if (
      titulosPremiados[index].comprador != null &&
      titulosPremiados[index].comprador != undefined &&
      titulosPremiados[index].comprador != ''
    ) {
      return;
    } else {
      setTitulosPremiados((prevState: any) => {
        const newTitulosPremiados = [...prevState]; // Crie uma cópia do array
        const item = { ...newTitulosPremiados[index] }; // Crie uma cópia do item
        if (item.status == 'liberado') item.status = 'trancar';
        else if (item.status == 'liberar') item.status = 'trancado';
        else if (item.status == 'trancado') item.status = 'liberar';
        else item.status = 'liberado';
        newTitulosPremiados[index] = item; // Substitua o item no array copiado
        return newTitulosPremiados;
      });
    }
  };

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: '' };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role);

      if (decodeJwt.role == 'suporte') {
        setAutorizacao(false);
        return;
      }

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const pathSegments = location.pathname.split('/');
    const segment = pathSegments[pathSegments.length - 1];
    setLink(segment);
    const getRaffle = async (link: string) => {
      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/raffles/getRaffle', {
          params: {
            link,
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj12312394a8945557',
          },
        })
        .then((response: any) => {
          setTitulosPremiados(response.data[0].cotasPremiadas);
          setRaffleSize(response.data[0].qtdCotas);
          setControleTitulos(response.data[0]?.controleTitulos == 'true' || response.data[0]?.controleTitulos == true ? true : false);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getRaffle(segment);
  }, []);

  const handleRemoveTitulosPremiados = (index: number) => {
    if (
      titulosPremiados[index].comprador != null &&
      titulosPremiados[index].comprador != undefined &&
      titulosPremiados[index].comprador != ''
    ) {
      return;
    }
    if (titulosPremiados[index].status === 'trancado') {
      handleToggleStatus(index);
    }
    setTitulosPremiados((prevTitulo: any) =>
      prevTitulo.filter((_: any, i: any) => i !== index),
    );
  };

  const saveChanges = async () => {
    setEnableSaveButton(false);
    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio: domain,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
      link,
      cotasPremiadas: titulosPremiados,
    };

    console.log(titulosPremiados);

    await api
      .put('/raffles/updateCotasPremiadas', data)
      .then((response: any) => {
        console.log(response);
        Swal.fire({
          icon: 'success',
          title: 'Alterações salvas com sucesso!',
          timer: 1500,
          timerProgressBar: true,
          confirmButtonColor: '#10B981',
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setEnableSaveButton(true);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleSearchOrderByCota = async (valorCota: string) => {
    setEnableSearchButton(false);
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;
    if (valorCota == '') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio: domain,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
      link,
      cota: valorCota,
    };

    await api
      .get('/orders/getOrderByNumber', { params: data })
      .then((response) => {
        navigate(`/checkout/${response.data.data?.id}`);
      })
      .catch((error) => {
        setEnableSearchButton(true);
        console.log(error);
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Não foi possível encontrar o pedido!',
          text: `${error.response.data.error}`,
        });
      });
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="sorteios" role={userRole} />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl w-full text-center">
                  Títulos premiados
                </h1>
                {enableSaveButton ? (
                  <>
                    <button
                      className="w-full h-fit py-2 bg-green-500 text-white rounded-xl mx-2 hover:bg-green-600 transition ease-in-out"
                      onClick={() => saveChanges()}
                    >
                      Salvar alterações
                    </button>
                  </>
                ) : (
                  <>
                    <button className="w-full h-fit py-2 flex flex-row justify-center text-center items-center bg-green-600 text-white rounded-xl mx-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 24 24"
                        className="mr-2"
                      >
                        <path
                          fill="white"
                          d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                          opacity="0.5"
                        />
                        <path
                          fill="white"
                          d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                        >
                          <animateTransform
                            attributeName="transform"
                            dur="0.75s"
                            from="0 12 12"
                            repeatCount="indefinite"
                            to="360 12 12"
                            type="rotate"
                          />
                        </path>
                      </svg>
                      Salvar alterações
                    </button>
                  </>
                )}
                <div className="w-full flex flex-row items-center">
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { my: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Buscar pedido pelo número do título"
                      variant="outlined"
                      color="success"
                      value={cotaSearch}
                      onChange={(e) =>
                        setCotaSearch(
                          masks.removeNonNumericChars(e.target.value),
                        )
                      }
                    />
                  </Box>
                  {enableSearchButton ? (
                    <>
                      <button
                        onClick={() => handleSearchOrderByCota(cotaSearch)}
                        className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl hover:bg-green-600 transition ease-in-out"
                      >
                        Buscar
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="w-fit h-fit p-2 bg-green-600 text-white rounded-xl">
                        Buscando pedido...
                      </button>
                    </>
                  )}
                </div>
                <div className="flex flex-col mt-2 h-auto max-h-1/2">
                  {titulosPremiados.map((item: any, index: number) => (
                    <div
                      className="flex flex-row bg-green-200 w-full p-3 my-1 rounded-xl"
                      key={index}
                    >
                      {
                        <>
                          {enableSearchButton ? (
                            <>
                              <div className="flex flex-row w-full justify-between">
                                <span className="flex flex-row font-medium text-green-900 w-full">
                                  <div className="flex flex-col justify-between w-full">
                                    <h1>Número: {item.titulo}</h1>
                                    <h1>Premiação: {item.nome}</h1>
                                    {item?.comprador != null &&
                                    item?.comprador != undefined &&
                                    item?.comprador != '' ? (
                                      <>
                                        <div
                                          onClick={() =>
                                            handleSearchOrderByCota(item.titulo)
                                          }
                                          className="flex flex-col cursor-pointer"
                                        >
                                          <h1>
                                            Comprador: {item?.comprador?.nome}
                                          </h1>
                                          <h1>
                                            Telefone:{' '}
                                            {item?.comprador?.telefone}
                                          </h1>
                                          <p className="text-xs text-orange-500 font-medium">
                                            Clique aqui para ir para o pedido!
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <h1>Comprador: Nenhum comprador!</h1>
                                      </>
                                    )}
                                  </div>
                                  {/* <button
                                    className="absolute bottom-12 right-0 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveTitulosPremiados(index);
                                    }}
                                  >
                                    &times;
                                  </button> */}
                                  {controleTitulos !== true ? (
                                    <></>
                                  ) : (
                                    <>
                                      <button
                                        className="px-3 h-fit w-fit flex items-center text-center justify-center"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleToggleStatus(index);
                                        }}
                                      >
                                        {item.status === 'liberado' ||
                                        item.status === 'liberar' ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.5em"
                                            height="1.5em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="red"
                                              d="M16 8c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V10c0-1.1.9-2 2-2h9V6c0-1.7-1.3-3-3-3S7 4.3 7 6H5c0-2.8 2.2-5 5-5s5 2.2 5 5v2zm-6 9c1.1 0 2-.9 2-2s-.9-2-2-2s-2 .9-2 2s.9 2 2 2m12-4h-2V7h2zm0 4h-2v-2h2z"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.5em"
                                            height="1.5em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
                                            />
                                          </svg>
                                        )}
                                      </button>
                                    </>
                                  )}
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="w-full flex flex-row justify-between">
                                <span className="flex flex-row font-medium text-green-900 w-full">
                                  <div className="flex flex-col justify-between w-full">
                                    <h1>Número: {item.titulo}</h1>
                                    <h1>Premiação: {item.nome}</h1>
                                    {item?.comprador != null &&
                                    item?.comprador != undefined &&
                                    item?.comprador != '' ? (
                                      <>
                                        <div className="flex flex-col">
                                          <h1>
                                            Comprador: {item?.comprador?.nome}
                                          </h1>
                                          <h1>
                                            Telefone:{' '}
                                            {item?.comprador?.telefone}
                                          </h1>
                                          <p className="text-xs text-orange-500 font-medium">
                                            BUSCANDO PEDIDO...
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <h1>Comprador: Nenhum comprador!</h1>
                                      </>
                                    )}
                                  </div>
                                  {/* <button
                                    className="absolute bottom-12 right-0 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveTitulosPremiados(index);
                                    }}
                                  >
                                    &times;
                                  </button> */}
                                  <button
                                    className="px-3 h-fit w-fit flex items-center text-center justify-center"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleToggleStatus(index);
                                    }}
                                  >
                                    {item.status === 'liberado' ||
                                    item.status === 'liberar' ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.5em"
                                        height="1.5em"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="red"
                                          d="M16 8c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V10c0-1.1.9-2 2-2h9V6c0-1.7-1.3-3-3-3S7 4.3 7 6H5c0-2.8 2.2-5 5-5s5 2.2 5 5v2zm-6 9c1.1 0 2-.9 2-2s-.9-2-2-2s-2 .9-2 2s.9 2 2 2m12-4h-2V7h2zm0 4h-2v-2h2z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.5em"
                                        height="1.5em"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="black"
                                          d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      }
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
