import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
const getBaseDomain = () => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    // Se houver mais de duas partes, remova a primeira (subdomínio)
    if (parts.length > 2) {
        parts.shift();
    }
    return parts.join('.');
};

// Função para construir a URL base dinamicamente
const getBaseURL = () => {
    const baseDomain = getBaseDomain();
    return `https://api.${baseDomain}/`;
};

const getUserId = () => {
    const buyerData = localStorage.getItem("buyer"); // Obtém o item do localStorage
    if (!buyerData) return null; // Retorna null se não encontrar o item
    try {
        const parsedData = JSON.parse(buyerData); // Faz o parse do JSON armazenado
        return parsedData.id || null; // Retorna o ID ou null se não existir
    } catch (error) {
        console.error("Erro ao parsear os dados do localStorage:", error);
        return null;
    }
};

const getEmailFromAppSession = () => {
    const appSessionToken = localStorage.getItem("appSession"); // Obtém o item do localStorage
    if (!appSessionToken) return null; // Retorna null se o token não estiver presente

    try {
        const decodedToken = jwtDecode(appSessionToken) as { email: string }; // Decodifica o token JWT
        return decodedToken.email || null; // Retorna o email do payload ou null se não existir
    } catch (error) {
        console.error("Erro ao decodificar o JWT:", error);
        return null;
    }
};

// Crie uma instância do axios com a URL base dinâmica
const apiClient = axios.create({
    baseURL: getBaseURL(),
    withCredentials: true,
    headers: {
        'X-User-Id': encodeURIComponent(getEmailFromAppSession() || getUserId()),
        'Content-type': 'application/json'
    }
});

export default apiClient;