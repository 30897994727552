/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import HeaderMain from '../generalComponents/HeaderMain';
import Hotbar from '../NewRaffle/components/Hotbar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ImgDropzone from '../NewRaffle/components/ImgDropzone';
import { useLocation } from 'react-router-dom';
import api from '../generalComponents/api';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import { Masks } from '../Masks/masks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function EditRaffle() {
  const navigate = useNavigate();
  const masks = new Masks();
  const location = useLocation();
  const buttons = [
    'Geral',
    'Títulos',
    'Images',
    'Premios',
    'Afiliados',
    'Premiação Inst.',
    'Pagamento',
    'Promoções',
    'Anti Spam',
    'Suporte',
  ];
  const [selectedButton, setSelectedButton] = useState(0);
  const [titulo, setTitulo] = useState('');
  const [link, setLink] = useState('');
  const [subTitulo, setSubTitulo] = useState('');
  const [privacidade, setPrivacidade] = useState('publico');
  const [mostrarSorteio, setMostrarSorteio] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [categoria, setCategoria] = useState('carro');
  const [modeloReserva, setModeloReserva] = useState('aleatorio');
  const [modalidade, setModalidade] = useState('loteria');
  //Imagino que esse texto seja a descrição do sorteio
  const [texto, setTexto] = useState('');
  const [textoStatus, setTextoStatus] = useState('');
  const [inicioVendas, setInicioVendas] = useState('imediato');
  const [dataSorteio, setDataSorteio] = useState<Dayjs | null>(null);
  const [dataAgendamento, setDataAgendamento] = useState<Dayjs | null>(null);
  const [encerrarNaData, setEncerrarNaData] = useState(false);
  const [exibirData, setExibirData] = useState(false);
  const [certificado, setCertificado] = useState('');
  //configuração dos campos
  const [requerNome, setRequerNome] = useState(true);
  const [requerEmail, setRequerEmail] = useState(true);
  const [requerTelefone, setRequerTelefone] = useState(true);
  // valores de cotas
  const [valorCotas, setValorCotas] = useState(0);
  const [quantidadeCotas, setQuantidadeCotas] = useState('100');
  const [tempoExpiracao, setTempoExpiracao] = useState('10');
  const [campanhaGratuita, setCampanhaGratuita] = useState(false);
  const [limiteCompras, setLimiteCompras] = useState('0');
  const [quantidadeMinima, setQuantidadeMinima] = useState('1');
  const [quantidadeMaxima, setQuantidadeMaxima] = useState(quantidadeCotas);
  const [cards, setCards] = useState([] as string[]);
  const [cardPupular, setCardPopular] = useState('');
  const [barraProgresso, setBarraProgresso] = useState(false);
  const [rankDiario, setRankDiario] = useState(false);
  const [rankGeral, setRankGeral] = useState(false);
  const [exibirValorRanking, setExibirValorRanking] = useState(false);
  const [mostrarPremios, setMostrarPremios] = useState(false);
  const [vendaMinima, setVendaMinima] = useState('');
  //imagens
  const [images, setImages] = useState([] as File[]);
  //premios
  const [premios, setPremios] = useState([] as any);
  const [tituloPremio, setTituloPremio] = useState('');
  //Afiliados
  const [permiteAfiliados, setPermiteAfiliados] = useState(false);
  const [restringirAfiliados, setRestringirAfiliados] = useState(false);
  //titulos premiados
  const [titulosPremiados, setTitulosPremiados] = useState([] as Array<object>);
  const [controleTitulos, setControleTitulos] = useState(false);

  //aux dos títulos premiados
  const [nomeTituloPremiado, setNomeTituloPremiado] = useState('');
  const [numeroTituloPremiado, setNumeroTituloPremiado] = useState('');
  //fim dos aux

  const [ativarTitulosPremiados, setAtivarTitulosPremiados] = useState(true);
  const [mostrarTitulosPremiados, setMostrarTitulosPremiados] = useState(false);
  const [textoTitulosPremiados, setTextoTitulosPremiados] = useState('');
  //pagamento
  const [banco, setBanco] = useState('mercadopago');
  const [bankApiKey, setBankApiKey] = useState('');
  //promoções
  const [promocoes, setPromocoes] = useState([] as any);
  const [ativarPromocoes, setAtivarPromocoes] = useState(true);
  const [tituloPromocao, setTituloPromocao] = useState('');
  const [valorPromocao, setValorPromocao] = useState('');
  //anti spam
  const [antiSpam, setAntiSpam] = useState(false);
  const [limiteReservas, setLimiteReservas] = useState('3');
  const [tempoBan, setTempoBan] = useState('3');
  //suporte
  const [widget, setWidget] = useState('textIcon');
  const [whatsApp, setWhatsApp] = useState('');
  const [grupoWhatsApp, setGrupoWhatsApp] = useState('');
  const [telegram, setTelegram] = useState('');
  const [Email, setEmail] = useState('');
  const [discord, setDiscord] = useState('');
  const [instagram, setInstagram] = useState('');
  const [oldImages, setOldImages] = useState([] as any);
  const [prioridade, setPrioridade] = useState('');
  const [autorizacao, setAutorizacao] = useState(false);
  //states auxiliares
  const [enableEditButton, setEnableEditButton] = useState(true);
  //configuração de cliente
  const [client, setClient] = useState({} as any);
  const [nivel, setNivel] = useState('');

  //começo das funções
  const getClientInfo = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/client/getAccountData', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        setClient(response.data);
        setNivel(response.data.nivel);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMaxNumberQuantity = (quantidade: string) => {
    const qtdNumerica = parseInt(quantidade);

    let maxLimit;
    if (nivel == '0') {
      maxLimit = 10000;
    } else if (nivel == '1') {
      maxLimit = 20000;
    } else if (nivel == '2') {
      maxLimit = 30000;
    } else if (nivel == '3') {
      maxLimit = 45000;
    } else {
      maxLimit = 50000;
    }

    if (qtdNumerica > maxLimit) {
      setQuantidadeMaxima(maxLimit.toString());
    } else {
      setQuantidadeMaxima(quantidade);
    }
  };

  const handleInput = (e: any) => {
    const quantidade = masks.removeNonNumericChars(e.target.value);
    const qtdNumerica = parseInt(quantidade);

    let maxLimit;
    if (nivel == '1') {
      maxLimit = 20000;
    } else if (nivel == '2') {
      maxLimit = 30000;
    } else if (nivel == '3') {
      maxLimit = 45000;
    } else {
      maxLimit = 50000;
    }

    if (qtdNumerica > maxLimit) {
      e.target.value = maxLimit.toString();
      setQuantidadeMaxima(maxLimit.toString());
    } else {
      setQuantidadeMaxima(quantidade);
    }
  };

  const handlerControlCards = (card: string, index: number) => {
    card = masks.removeNonNumericChars(card);
    const obj = [...cards]; // Crie uma cópia do array `cards`
    obj[index] = card; // Modifique o item na cópia
    setCards(obj); // Defina o estado com a cópia modificada
  };

  const handleSubmitPremios = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (tituloPremio.trim() !== '') {
      premios.push(tituloPremio);
      setTituloPremio('');
    }
  };

  const handleRemovePremios = (index: number) => {
    setPremios((prevPremio: any) =>
      prevPremio.filter((_: any, i: any) => i !== index),
    );
  };

  const handleSubmitTitulosPremiados = (
    e: React.FormEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    const obj: any = {};
    obj.titulo = masks.setZeroLeft(
      numeroTituloPremiado,
      quantidadeCotas.toString().length - 1,
    );
    obj.nome = nomeTituloPremiado;
    if (
      nomeTituloPremiado.trim() !== '' &&
      numeroTituloPremiado.trim() !== ''
    ) {
      titulosPremiados.push(obj);
      setNomeTituloPremiado('');
      setNumeroTituloPremiado('');
    }
  };

  const handleRemoveTitulosPremiados = (index: number) => {
    setTitulosPremiados((prevTitulo) =>
      prevTitulo.filter((_, i) => i !== index),
    );
  };

  const handlerSubmitPromocoes = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const obj = {
      quantidade: tituloPromocao,
      valor: String(parseFloat(valorPromocao) / 100),
    };
    if (obj.quantidade.trim() !== '' && obj.valor.trim() !== '') {
      setPromocoes((prevPromocoes: any) => [...prevPromocoes, obj]); // Adiciona ao array existente
      setTituloPromocao('');
      setValorPromocao('');
    }
  };

  const handleRemovePromocoes = (index: number) => {
    setPromocoes((prevPromocao: any) =>
      prevPromocao.filter((_: any, i: any) => i !== index),
    );
  };

  useEffect(() => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const pathSegments = location.pathname.split('/');
    const segment = pathSegments[pathSegments.length - 1];
    setLink(segment);
    loadRaffle(segment);
  }, [location.pathname]);

  const loadRaffle = async (linkraffle: string) => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/raffles/getRaffle', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj12312394a8945557',
          link: linkraffle,
        },
      })
      .then((response: any) => {
        setTitulo(response.data[0].nome);
        setSubTitulo(response.data[0].subtitulo);
        setPrivacidade(response.data[0].privacidade);
        setMostrarSorteio(response.data[0].mostrarSite);
        setMostrarTitulosPremiados(response.data[0].mostrarTitulo);
        setControleTitulos(response.data[0]?.controleTitulos || false);
        setCategoria(response.data[0].tipo);
        setModeloReserva(response.data[0].metodoSorteio);
        setModalidade(response.data[0]?.modalidade);
        setTexto(response.data[0].descricao);
        if (!response.data[0].lancamento) {
          setInicioVendas('imediato');
        } else {
          setInicioVendas('agendado');
        }
        setDataAgendamento(dayjs(response.data[0].dataLancamento));
        setDataSorteio(dayjs(response.data[0].dataSorteio));
        setEncerrarNaData(response.data[0].encerrarAutomatico);
        setExibirData(response.data[0].ocultarDataSorteio);
        setCertificado(response.data[0].certificado);
        setValorCotas(response.data[0].precoU);
        setQuantidadeCotas(String(response.data[0].qtdCotas));
        setVendaMinima(String(response.data[0].vendaMinima));
        setTempoExpiracao(response.data[0].tempoExpiracao);
        setCampanhaGratuita(response.data[0].sorteioGratuito);
        setLimiteCompras(response.data[0].qtdMaxComprador);
        setQuantidadeMinima(response.data[0].cotasMin);
        setQuantidadeMaxima(response.data[0].cotasMax);
        setPrioridade(response.data[0].prioridade);
        response.data[0].pacoteCotas.forEach((pacote: any, index: number) => {
          cards[index] = pacote.quantidade;
          if (pacote.indicador == 'true') setCardPopular(String(index));
        });
        setBarraProgresso(response.data[0].barraProgresso);
        setRankDiario(response.data[0].exibirRankingDiario);
        setRankGeral(response.data[0].exibirRankingCompradores);
        setExibirValorRanking(response.data[0]?.exibirValorRanking || false);
        setOldImages(response.data[0].imagens);
        setMostrarPremios(response.data[0].mostrarPremios);
        setPremios(response.data[0].premios);
        setPermiteAfiliados(Boolean(response.data[0].afiliados));
        if (
          !response.data[0].cotasPremiadas ||
          response.data[0].cotasPremiadas.length == 0
        ) {
          setAtivarTitulosPremiados(false);
        }
        response.data[0].cotasPremiadas.forEach((obj: any) =>
          titulosPremiados.push(obj),
        );
        setBanco(response.data[0].banco);
        setPromocoes(response.data[0].desconto);
        if (
          !response.data[0].desconto ||
          response.data[0].desconto.length == 0
        ) {
          setAtivarPromocoes(false);
        }
        if (response.data[0].antiSpam.ativado == 'true') {
          setAntiSpam(true);
          setLimiteReservas(response.data[0].antiSpam.limiteReservas);
          setTempoBan(response.data[0].antiSpam.tempoBan);
        }
        setWidget(response.data[0].redes.widget);
        setWhatsApp(response.data[0].redes.wpp);
        setGrupoWhatsApp(response.data[0].redes.grupoWpp);
        setTelegram(response.data[0].redes.telegram);
        setDiscord(response.data[0].redes.discord);
        setInstagram(response.data[0].redes.instagram);
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const renderFormContent = () => {
    switch (selectedButton) {
      case 0:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center m-1">
              Formulário Geral
            </div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Título *"
                variant="outlined"
                color="success"
                value={titulo}
                onChange={(e: any) => setTitulo(e.target.value)}
              />
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Subtítulo *"
                variant="outlined"
                color="success"
                value={subTitulo}
                onChange={(e: any) => setSubTitulo(e.target.value)}
              />
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Link *"
                variant="outlined"
                color="success"
                value={link}
                onChange={(e: any) => setLink(e.target.value)}
              />
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Prioridade *"
                variant="outlined"
                color="success"
                value={prioridade}
                onChange={(e: any) => setPrioridade(e.target.value)}
              />
            </Box>
            <div className="mt-2 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Privacidade *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Privacidade *"
                  value={privacidade}
                  onChange={(e: any) => setPrivacidade(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'publico'}>Público</MenuItem>
                  <MenuItem value={'privado'}>Privado</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex mt-4 items-center">
              <Switch
                checked={mostrarSorteio}
                color="success"
                onClick={() => setMostrarSorteio(!mostrarSorteio)}
              />
              <span>Mostrar sorteio na página principal *</span>
            </div>
            <div className="mt-4 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Categoria *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Categoria *"
                  value={categoria}
                  onChange={(e: any) => setCategoria(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'carro'}>Carro</MenuItem>
                  <MenuItem value={'moto'}>Moto</MenuItem>
                  <MenuItem value={'dinheiro'}>Dinheiro</MenuItem>
                  <MenuItem value={'eletronico'}>Eletônico</MenuItem>
                  <MenuItem value={'celular'}>Celular</MenuItem>
                  <MenuItem value={'diversos'}>Diversos</MenuItem>
                  <MenuItem value={'outros'}>Outros</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="mt-4 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Modelo de reserva *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Modelo de reserva *"
                  value={modeloReserva}
                  onChange={(e: any) => setModeloReserva(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'aleatorio'}>Números aleatórios</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="mt-4 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Modalidade do sorteio *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Modalidade do sorteio *"
                  value={modalidade}
                  onChange={(e: any) => setModalidade(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'loteria'}>Loteria Federal</MenuItem>
                  <MenuItem value={'giroAleatorio'}>Giro da sorte</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="max-h-full w-full p-2">
              <h1 className="my-1">Descrição do sorteio:</h1>
              <ReactQuill
                theme="snow"
                value={texto}
                onChange={(e) => setTexto(e)}
                placeholder='Escreva aqui a descrição do sorteio. Ex: "Sorteio de um carro zero km, participe e concorra!"'
              />
            </div>
            <div className="mt-2 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Início das vendas *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Início das vendas *"
                  value={inicioVendas}
                  onChange={(e: any) => setInicioVendas(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'imediato'}>Imediatamente</MenuItem>
                  <MenuItem value={'agendado'}>Agendado</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inicioVendas === 'agendado' ? (
              <>
                <div className="flex px-2 mt-4 w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Data de agendamento *"
                      value={dataAgendamento}
                      onChange={(newValue: Dayjs | null) => {
                        setDataAgendamento(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="flex px-2 mt-4 w-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Data do sorteio *"
                  value={dataSorteio}
                  onChange={(newValue: Dayjs | null) => {
                    setDataSorteio(newValue);
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="flex mt-4 items-center">
              <Switch
                checked={encerrarNaData}
                color="success"
                onClick={() => setEncerrarNaData(!encerrarNaData)}
              />
              <span>Encerrar automaticamente na data do sorteio *</span>
            </div>
            <div className="flex mt-4 items-center">
              <Switch
                checked={exibirData}
                color="success"
                onClick={() => setExibirData(!exibirData)}
              />
              <span>Mostrar data do sorteio para participantes *</span>
            </div>
            <div className="mt-4 flex flex-col w-full border rounded-xl p-2">
              <h1>Campos adicionais no login</h1>
            </div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Processo SUSEP *"
                variant="outlined"
                color="success"
                value={certificado}
                onChange={(e: any) => setCertificado(e.target.value)}
              />
            </Box>
          </div>
        );
      case 1:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração dos títulos
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                disabled
                checked={campanhaGratuita}
                color="success"
                onClick={() => setCampanhaGratuita(!campanhaGratuita)}
              />
              <span>Campanha gratuita</span>
            </div>
            <div className="flex flex-row max-w-full max-h-fit w-full">
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                {campanhaGratuita ? (
                  <>
                    <TextField
                      disabled
                      id="outlined-basic"
                      label="Valor por título *"
                      variant="outlined"
                      color="success"
                      value={String(valorCotas.toFixed(2))}
                      onChange={(e: any) => setValorCotas(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      id="outlined-basic"
                      label="Valor por título *"
                      variant="outlined"
                      color="success"
                      value={valorCotas}
                      type="number"
                      disabled
                      onChange={(e: any) => setValorCotas(e.target.value)}
                    />
                  </>
                )}
              </Box>
              <div className="mt-2 px-2 w-full">
                <FormControl disabled color="success" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Quantidade de títulos *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Quantidade de títulos *"
                    value={quantidadeCotas}
                    onChange={(e: any) => setQuantidadeCotas(e.target.value)}
                    color="success"
                  >
                    <MenuItem value={'100'}>100</MenuItem>
                    <MenuItem value={'1000'}>1.000</MenuItem>
                    <MenuItem value={'10000'}>10 mil</MenuItem>
                    <MenuItem value={'100000'}>100 mil</MenuItem>
                    <MenuItem value={'1000000'}>1 Milhão</MenuItem>
                    <MenuItem value={'10000000'}>10 Milhões</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="mt-2 px-2 w-full">
                <FormControl color="success" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tempo de expiração *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tempo de expiração *"
                    value={tempoExpiracao}
                    onChange={(e: any) => setTempoExpiracao(e.target.value)}
                    color="success"
                  >
                    <MenuItem value={'10'}>10 minutos</MenuItem>
                    <MenuItem value={'15'}>15 minutos</MenuItem>
                    <MenuItem value={'20'}>20 minutos</MenuItem>
                    <MenuItem value={'25'}>25 minutos</MenuItem>
                    <MenuItem value={'30'}>30 minutos</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex flex-row w-full max-w-full">
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Quantidade máxima de títulos por comprador * (0 = desabilitado)"
                  variant="outlined"
                  color="success"
                  value={limiteCompras}
                  onChange={(e: any) => setLimiteCompras(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Quantidade mínima por reserva *"
                  variant="outlined"
                  color="success"
                  value={quantidadeMinima}
                  onChange={(e: any) => setQuantidadeMinima(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Quantidade máxima por reserva *"
                  variant="outlined"
                  color="success"
                  value={quantidadeMaxima}
                  onChange={(e: any) =>
                    handleMaxNumberQuantity(
                      masks.removeNonNumericChars(e.target.value),
                    )
                  }
                  onInput={handleInput}
                  inputProps={{
                    max:
                      nivel == '1'
                        ? 20000
                        : nivel == '2'
                          ? 30000
                          : nivel == '3'
                            ? 45000
                            : 50000,
                    type: 'number',
                  }}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                autoComplete="off"
              >
                <TextField
                  disabled
                  id="outlined-basic"
                  label="Venda mínima do sorteio *"
                  variant="outlined"
                  color="success"
                  value={vendaMinima}
                  onChange={(e: any) => {
                    if (e.target.value < parseInt(quantidadeCotas)) {
                      masks.removeNonNumericChars(e.target.value);
                    }
                  }}
                />
              </Box>
            </div>
            <div className="flex flex-col w-full my-4 max-w-full">
              <h1 className="mx-2 font-medium my-1">
                Configuração dos cards de seleção
              </h1>
              <div className="flex flex-row w-full">
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 1 *"
                    variant="outlined"
                    color="success"
                    value={cards[0]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 0)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 2 *"
                    variant="outlined"
                    color="success"
                    value={cards[1]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 1)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 3 *"
                    variant="outlined"
                    color="success"
                    value={cards[2]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 2)
                    }
                  />
                </Box>
              </div>
              <div className="flex flex-row w-full">
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 4 *"
                    variant="outlined"
                    color="success"
                    value={cards[3]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 3)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 5 *"
                    variant="outlined"
                    color="success"
                    value={cards[4]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 4)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 6 *"
                    variant="outlined"
                    color="success"
                    value={cards[5]}
                    onChange={(e: any) => {
                      handlerControlCards(e.target.value, 5);
                    }}
                  />
                </Box>
              </div>
            </div>
            <div className="mt-2 px-2 w-full">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Card mais popular *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Card mais popular *"
                  value={cardPupular}
                  onChange={(e: any) => setCardPopular(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'0'}>1</MenuItem>
                  <MenuItem value={'1'}>2</MenuItem>
                  <MenuItem value={'2'}>3</MenuItem>
                  <MenuItem value={'3'}>4</MenuItem>
                  <MenuItem value={'4'}>5</MenuItem>
                  <MenuItem value={'5'}>6</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                checked={barraProgresso}
                color="success"
                onClick={() => setBarraProgresso(!barraProgresso)}
              />
              <span>Mostrar barra de progresso de vendas *</span>
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                checked={rankDiario}
                color="success"
                onClick={() => setRankDiario(!rankDiario)}
              />
              <span>Mostrar ranking diário de compradores *</span>
            </div>
            <div className="flex mt-2 items-center pb-5">
              <Switch
                checked={rankGeral}
                color="success"
                onClick={() => setRankGeral(!rankGeral)}
              />
              <span>Mostrar ranking geral de compradores *</span>
            </div>
            <div className="flex mt-2 items-center pb-5">
              <Switch
                checked={exibirValorRanking}
                color="success"
                onClick={() => setExibirValorRanking(!exibirValorRanking)}
              />
              <span>Exibir valor em R$ nos rankings *</span>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center mb-2">
              Envie suas imagens
            </div>
            <ImgDropzone
              oldImages={oldImages}
              setOldImages={setOldImages}
              images={images}
              setImages={setImages}
              old={true}
            />
          </div>
        );
      case 3:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Informe os prêmios do sorteio
            </div>
            <div className="flex flex-col mt-2 w-full">
              <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                  className="mr-2 min-w-8 min-h-8"
                >
                  <path
                    fill="#0aa1ff"
                    d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                  />
                </svg>
                <h1 className="font-medium text-sky-800">
                  Informe quais os prêmios os participantes estarão concorrendo.
                  Você pode informar até 10 prêmios.
                </h1>
              </div>
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                checked={mostrarPremios}
                color="success"
                onClick={() => setMostrarPremios(!mostrarPremios)}
              />
              <span>Mostrar prêmios na página do sorteio *</span>
            </div>
            <div className="flex flex-col items-center">
              {/* <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
                className="flex flex-col lg:flex-row"
              >
                <TextField
                  id="outlined-basic"
                  label="Prêmio *"
                  variant="outlined"
                  color="success"
                  value={tituloPremio}
                  onChange={(e) => setTituloPremio(e.target.value)}
                />
                <button
                  className="bg-green-500 text-xl w-fit text-white p-2 rounded-lg hover:bg-green-700 transition-all duration-200 ease-in-out"
                  onClick={handleSubmitPremios}
                >
                  Adicionar
                </button>
              </Box> */}
              <div className="mt-4 w-full">
                {premios.length > 0 ? (
                  <>
                    <div className="list-disc">
                      {premios?.map((premio: any, index: number) => (
                        <div
                          key={index}
                          className="relative text-lg m-1 px-3 py-2 font-medium text-gray-700 bg-green-200 rounded-xl"
                        >
                          {premio?.titulo}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de afiliados
            </div>
            <div className="flex flex-col w-full mt-4">
              <div className="flex mt-2 items-center">
                <Switch
                  checked={permiteAfiliados}
                  color="success"
                  onClick={() => setPermiteAfiliados(!permiteAfiliados)}
                />
                <span>Permitir afialiados</span>
              </div>
              <div className="flex bg-green-100 rounded-xl p-3 mt-2 text-lg">
                O código de afiliado funciona da seguinte maneira:
                <br />
                <br /> Quando um usuário acessa o site através de um link de
                afiliado, o código de afiliado é passado como um parâmetro na
                URL, como no exemplo: https://site.com?afiliado=123.
                <br />
                <br /> O site então armazena esse código de afiliado em um
                cookie no navegador do usuário. Isso permite que o site rastreie
                que o usuário foi encaminhado por um afiliado específico.
                <br />
                <br /> Enquanto o cookie estiver ativo, o código de afiliado
                será associado a todas as interações do usuário no site,
                independentemente dos produtos ou páginas acessadas.
                <br />
                <br />
                Inclusive se o usuário sair do site e voltar mais tarde, ou
                acessar com outra conta, também será rastreado. No entanto, se o
                usuário limpar os cookies do navegador ou acessar o site de
                outra forma (por exemplo, de outro dispositivo), o código de
                afiliado não será passado e não será rastreado. Exceto se
                acessar através do link de afiliado.
                <br />
                <br /> Caso o usuário entre no site através de um novo código de
                afiliado, o cookie será atualizado para o novo código.
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Premiação instantânea
            </div>
            <div className="flex flex-col w-full mt-4">
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#0aa1ff"
                      d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                    />
                  </svg>
                  <h1 className="font-medium text-sky-800">
                    Cadastre Títulos Premiados para os participantes. Você pode
                    cadastrar de 1 até 200 títulos.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col w-full mt-4">
                <div className="flex flex-row w-full p-2 lg:px-10 lg:py-2 bg-amber-100 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#ffca0a"
                      d="M2.725 21q-.275 0-.5-.137t-.35-.363t-.137-.488t.137-.512l9.25-16q.15-.25.388-.375T12 3t.488.125t.387.375l9.25 16q.15.25.138.513t-.138.487t-.35.363t-.5.137zM12 18q.425 0 .713-.288T13 17t-.288-.712T12 16t-.712.288T11 17t.288.713T12 18m0-3q.425 0 .713-.288T13 14v-3q0-.425-.288-.712T12 10t-.712.288T11 11v3q0 .425.288.713T12 15"
                    />
                  </svg>
                  {controleTitulos ? (
                    <>
                      <h1 className="font-medium text-amber-800">
                        <strong>Atenção:</strong> Os títulos premiados serão
                        liberados por padrão. Após o cadastro, você poderá
                        liberar os títulos premiados para os participantes
                        conforme desejar.{' '}
                        <strong>
                          Não é possível definir novos títulos premiados após a
                          criação do sorteio!
                        </strong>
                      </h1>
                    </>
                  ) : (
                    <>
                      <h1 className="font-medium text-amber-800">
                        <strong>
                          Atenção: Não é possível definir novos títulos
                          premiados após a criação do sorteio!
                        </strong>
                      </h1>
                    </>
                  )}
                </div>
              </div>
              <div className="flex mt-4 items-center">
                <Switch
                  checked={ativarTitulosPremiados}
                  color="success"
                  onClick={() =>
                    setAtivarTitulosPremiados(!ativarTitulosPremiados)
                  }
                />
                <span>Ativar premiação instantânea</span>
              </div>
              {ativarTitulosPremiados ? (
                <>
                  <div className="flex mt-4 items-center">
                    <Switch
                      checked={mostrarTitulosPremiados}
                      color="success"
                      onClick={() =>
                        setMostrarTitulosPremiados(!mostrarTitulosPremiados)
                      }
                    />
                    <span>Mostrar títulos premiados na página do sorteio</span>
                  </div>
                  {/* <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Texto a ser exibido para os ganhadores do títulos premiados *"
                      variant="outlined"
                      color="success"
                      value={textoTitulosPremiados}
                      onChange={(e: any) =>
                        setTextoTitulosPremiados(e.target.value)
                      }
                    />
                  </Box>
                  <div className="flex flex-row w-full items-center">
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '98%' },
                        width: '100%',
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Título premiado *"
                        variant="outlined"
                        color="success"
                        value={nomeTituloPremiado}
                        onChange={(e: any) =>
                          setNomeTituloPremiado(e.target.value)
                        }
                      />
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '98%' },
                        width: '100%',
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Número do título premiado *"
                        variant="outlined"
                        color="success"
                        value={numeroTituloPremiado}
                        onChange={(e: any) =>
                          setNumeroTituloPremiado(
                            masks.removeNonNumericChars(e.target.value),
                          )
                        }
                      />
                    </Box>
                    <button
                      className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600 transition-all duration-200 ease-in-out"
                      onClick={(e) => handleSubmitTitulosPremiados(e)}
                    >
                      Adicionar
                    </button>
                  </div> */}
                  <div className="flex flex-col mt-4">
                    {titulosPremiados?.map((item: any, index: any) => (
                      <div
                        className="flex flex-row bg-green-200 w-full p-3 my-1 rounded-xl"
                        key={index}
                      >
                        {
                          <>
                            <span className="relative font-medium text-green-900 w-full">
                              Premiação: {item.nome} - Número: {item.titulo}
                              {/* <button
                                className="absolute bottom-0 right-0 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveTitulosPremiados(index);
                                }}
                              >
                                &times;
                              </button> */}
                            </span>
                          </>
                        }
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      case 6:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de pagamento
            </div>
            <div className="flex flex-col w-full mt-4">
              <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                  className="mr-2 min-w-8 min-h-8"
                >
                  <path
                    fill="#0aa1ff"
                    d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                  />
                </svg>
                <h1 className="font-medium text-sky-800">
                  Os pagamentos das reservas são processados exclusivamente por
                  meio dos provedores de pagamentos online, que executam a baixa
                  do pagamento de forma automática. Não são aceitas
                  transferências diretas; pedimos que todos os pagamentos sejam
                  feitos utilizando os provedores disponibilizados.
                </h1>
              </div>
            </div>
            <div className="mt-4 px-2 w-full">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Plataforma de pagamento *
                </InputLabel>
                <Select
                  disabled
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Plataforma de pagamento *"
                  value={banco}
                  onChange={(e: any) => setBanco(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'mercadopago'}>Mercado pago</MenuItem>
                  <MenuItem value={'efibank'}>Efí Pay (Efí Bank)</MenuItem>
                  <MenuItem value={'pay2m'}>Pay2m</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              {banco == 'efibank' ? (
                <>
                  <TextField
                    id="outlined-basic"
                    label="Token *"
                    variant="outlined"
                    color="success"
                    value={bankApiKey}
                    onChange={(e: any) => setBankApiKey(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
            </Box>
          </div>
        );
      case 7:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de promoções
            </div>
            <div className="flex flex-col mt-4 w-full">
              <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                  className="mr-2 min-w-8 min-h-8"
                >
                  <path
                    fill="#0aa1ff"
                    d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                  />
                </svg>
                <h1 className="font-medium text-sky-800">
                  Ofereça para seus clientes opções de promoções. Você pode
                  cadastrar de 1 até 10 promoções. A promoção é aplicada em
                  forma de porcentagem, então escreva 10 se quiser aplicar 10%
                  de desconto sobre o valor do número de títulos. O sistema
                  converterá automaticamente para o formato decimal. LEMBRANDO
                  QUE 1% = 0.01, 10% = 0.1, 20% = 0.2 E ASSIM POR DIANTE.
                </h1>
              </div>
              <div className="flex mt-2 items-center">
                <Switch
                  checked={ativarPromocoes}
                  color="success"
                  onClick={() => setAtivarPromocoes(!ativarPromocoes)}
                />
                <span>Ativar promoções</span>
              </div>
              {ativarPromocoes ? (
                <>
                  <div className="flex flex-col w-full">
                    <h1 className="px-2 montserrat-500">Promoções</h1>
                    <div className="flex flex-row w-full items-center">
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Quantidade de títulos da promoção (Quantidade de títulos a ser aplicada desconto)*"
                          variant="outlined"
                          color="success"
                          value={tituloPromocao}
                          onChange={(e: any) =>
                            setTituloPromocao(
                              masks.removeNonNumericChars(e.target.value),
                            )
                          }
                        />
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Porcentagem de desconto (O desconto é aplicado sobre o valor em forma de porcentagem Ex.: 10% de R$100,00 = R$ 10,00)*"
                          variant="outlined"
                          color="success"
                          value={valorPromocao}
                          onChange={(e: any) =>
                            setValorPromocao(
                              masks.removeNonNumericChars(e.target.value),
                            )
                          }
                        />
                      </Box>
                      <button
                        className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600 transition-all duration-200 ease-in-out"
                        onClick={(e) => handlerSubmitPromocoes(e)}
                      >
                        Adicionar
                      </button>
                    </div>
                    <div className="flex flex-col w-full">
                      {promocoes.length > 0 ? (
                        <>
                          {promocoes?.map((item: any, index: any) => (
                            <div
                              className="flex flex-row bg-green-200 w-full p-3 my-1 rounded-xl"
                              key={index}
                            >
                              {
                                <>
                                  <span className="relative font-medium text-green-900 w-full">
                                    Quantidade: {item?.quantidade} - Desconto:{' '}
                                    {item?.valor * 100}%
                                    <button
                                      className="absolute bottom-0 right-0 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemovePromocoes(index);
                                      }}
                                    >
                                      &times;
                                    </button>
                                  </span>
                                </>
                              }
                            </div>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      case 8:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de anti-spam
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-col w-full mt-4">
                <div className="flex flex-row w-full p-2 lg:px-10 lg:py-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#0aa1ff"
                      d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                    />
                  </svg>
                  <h1 className="font-medium text-sky-800">
                    O participante faz 3 reservas seguidas sem pagar, ele é
                    bloqueado e não pode fazer mais reservas até que o tempo de
                    bloqueio acabe.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col w-full mt-4">
                <div className="flex flex-row w-full p-2 lg:px-10 lg:py-2 bg-amber-100 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#ffca0a"
                      d="M2.725 21q-.275 0-.5-.137t-.35-.363t-.137-.488t.137-.512l9.25-16q.15-.25.388-.375T12 3t.488.125t.387.375l9.25 16q.15.25.138.513t-.138.487t-.35.363t-.5.137zM12 18q.425 0 .713-.288T13 17t-.288-.712T12 16t-.712.288T11 17t.288.713T12 18m0-3q.425 0 .713-.288T13 14v-3q0-.425-.288-.712T12 10t-.712.288T11 11v3q0 .425.288.713T12 15"
                    />
                  </svg>
                  <h1 className="font-medium text-amber-800">
                    <strong>Atenção:</strong> O Anti Spam consiste em bloquear
                    temporariamente usuários que fazem muitas reservas sem
                    pagar. O bloqueio é feito por um tempo determinado e após o
                    tempo de bloqueio o usuário pode fazer reservas normalmente.
                  </h1>
                </div>
              </div>
              <div className="flex mt-2 items-center">
                <Switch
                  checked={antiSpam}
                  color="success"
                  onClick={() => setAntiSpam(!antiSpam)}
                />
                <span>Ativar Anti Spam</span>
              </div>
              {antiSpam ? (
                <>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Limite de reservas sem pagar *"
                      variant="outlined"
                      color="success"
                      value={limiteReservas}
                      onChange={(e: any) => setLimiteReservas(e.target.value)}
                    />
                  </Box>
                  <div className="mt-2 px-2 w-full">
                    <FormControl color="success" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tempo de banimento *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tempo de banimento *"
                        value={tempoBan}
                        onChange={(e: any) => setTempoBan(e.target.value)}
                        color="success"
                      >
                        <MenuItem value={'1'}>1 Hora</MenuItem>
                        <MenuItem value={'2'}>2 Horas</MenuItem>
                        <MenuItem value={'3'}>3 Horas</MenuItem>
                        <MenuItem value={'4'}>4 Horas</MenuItem>
                        <MenuItem value={'5'}>5 Horas</MenuItem>
                        <MenuItem value={'6'}>6 Horas</MenuItem>
                        <MenuItem value={'24'}>1 Dia</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              ) : (
                <>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      disabled
                      id="outlined-basic"
                      label="Limite de reservas sem pagar *"
                      variant="outlined"
                      color="success"
                      value={limiteReservas}
                      onChange={(e: any) => setLimiteReservas(e.target.value)}
                    />
                  </Box>
                  <div className="mt-2 px-2 w-full">
                    <FormControl color="success" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tempo de banimento *
                      </InputLabel>
                      <Select
                        disabled
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tempo de banimento *"
                        value={tempoBan}
                        onChange={(e: any) => setTempoBan(e.target.value)}
                        color="success"
                      >
                        <MenuItem value={'1'}>1 Hora</MenuItem>
                        <MenuItem value={'2'}>2 Horas</MenuItem>
                        <MenuItem value={'3'}>3 Horas</MenuItem>
                        <MenuItem value={'4'}>4 Horas</MenuItem>
                        <MenuItem value={'5'}>5 Horas</MenuItem>
                        <MenuItem value={'6'}>6 Horas</MenuItem>
                        <MenuItem value={'24'}>1 Dia</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case 9:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configurações do suporte ao cliente
            </div>
            <div className="flex flex-col w-full">
              <div className="mt-2 px-2 w-full">
                <FormControl color="success" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tipo do widget *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tipo do widget *"
                    value={widget}
                    onChange={(e: any) => setWidget(e.target.value)}
                    color="success"
                  >
                    <MenuItem value={'textIcon'}>Ícone e texto</MenuItem>
                    <MenuItem value={'icon'}>Apenas ícone</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Whatsapp *"
                  variant="outlined"
                  color="success"
                  value={whatsApp}
                  onChange={(e: any) => setWhatsApp(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Grupo do whatsapp *"
                  variant="outlined"
                  color="success"
                  value={grupoWhatsApp}
                  onChange={(e: any) => setGrupoWhatsApp(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Telegram *"
                  variant="outlined"
                  color="success"
                  value={telegram}
                  onChange={(e: any) => setTelegram(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Discord *"
                  variant="outlined"
                  color="success"
                  value={discord}
                  onChange={(e: any) => setDiscord(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Instagram *"
                  variant="outlined"
                  color="success"
                  value={instagram}
                  onChange={(e: any) => setInstagram(e.target.value)}
                />
              </Box>
            </div>
          </div>
        );
      default:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">Formulário Geral</div>
          </div>
        );
    }
  };

  const saveChanges = async () => {
    setEnableEditButton(false);
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;

    if (whatsApp == '') {
      setWhatsApp('https://wa.me/+55');
    }
    if (telegram == '') {
      setTelegram('https://t.me/');
    }
    if (discord == '') {
      setDiscord('https://discord.com/');
    }
    if (instagram == '') {
      setInstagram('https://instagram.com/');
    }
    if (grupoWhatsApp == '') {
      setGrupoWhatsApp('https://chat.whatsapp.com/');
    }

    const dominio = window.location.hostname;

    let status;
    let lancamento;
    if (inicioVendas == 'imediato') {
      status = 'ativo';
      lancamento = false;
    } else {
      lancamento = true;
      status = 'aguardandoLancamento';
    }

    const formData = new FormData();

    const loginFields = {
      nome: requerNome,
      telefone: requerTelefone,
      email: requerEmail,
    };

    const dataSorteioBrasil = dayjs(dataSorteio)
      .tz('America/Sao_Paulo')
      .format();

    let dataAgendamentoBrasil;
    if (dataAgendamento != undefined)
      dataAgendamentoBrasil = dayjs(dataAgendamento)
        .tz('America/Sao_Paulo')
        .format();

    formData.append('dominio', dominio);
    formData.append('nome', titulo);
    formData.append('precoU', String(valorCotas));
    formData.append('qtdCotas', quantidadeCotas);
    formData.append('qtdCotasVendidas', '0');
    formData.append('porcentagemVendida', '0');
    formData.append('tempoExpiracao', tempoExpiracao);
    formData.append('dataSorteio', String(dataSorteioBrasil));
    formData.append('barraProgresso', String(barraProgresso));
    formData.append('rankingCompradores', String(rankGeral));
    formData.append('rankingDiario', String(rankDiario));
    formData.append('exibirValorRanking', String(exibirValorRanking));
    formData.append('subtitulo', subTitulo);
    formData.append('link', link);
    formData.append('cotasMin', quantidadeMinima);
    formData.append('cotasMax', quantidadeMaxima);
    formData.append('vendaMinima', vendaMinima);
    formData.append('mostrarSite', String(mostrarSorteio));
    formData.append('mostrarTitulo', String(mostrarTitulosPremiados));
    formData.append('controleTitulos', String(controleTitulos));
    formData.append('status', status);
    formData.append('tipo', categoria);
    formData.append('grupoWpp', grupoWhatsApp);
    formData.append('grupoTelegram', 'empty');
    formData.append('certificado', certificado);
    formData.append('regulamento', 'empty');
    formData.append('capitalizadora', 'empty');
    formData.append('descricao', texto);
    formData.append('banco', banco);
    formData.append('ocultarDataSorteio', String(exibirData));
    formData.append('sorteioGratuito', String(campanhaGratuita));
    formData.append('metodoSorteio', modeloReserva);
    formData.append('modalidade', modalidade);
    formData.append('privacidade', privacidade);
    formData.append('lancamento', String(lancamento));
    formData.append('dataLancamento', String(dataAgendamentoBrasil));
    formData.append('encerrarAutomatico', String(encerrarNaData));
    formData.append('qtdMaxComprador', limiteCompras);
    formData.append(
      'accessToken',
      'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    );
    formData.append('mostrarPremios', String(mostrarPremios));
    formData.append('widget', widget);
    formData.append('wpp', whatsApp);
    formData.append('discord', discord);
    formData.append('instagram', instagram);
    formData.append('telegram', telegram);
    formData.append('loginFields', JSON.stringify(loginFields));

    promocoes.forEach((promocao: any, index: any) => {
      formData.append(
        `desconto[${index}][quantidade]`,
        String(promocao.quantidade),
      );
      formData.append(`desconto[${index}][valor]`, String(promocao.valor));
    });

    cards.forEach((valor, index) => {
      formData.append(`pacoteCotas[${index}][quantidade]`, String(valor));
      formData.append(`pacoteCotas[${index}][indicador]`, String(false));
    });

    formData.set(
      `pacoteCotas[${parseInt(cardPupular)}][indicador]`,
      String(true),
    );

    if (oldImages.length != 0) {
      oldImages.forEach((image: any, index: any) => {
        formData.append(`oldImages[${index}]`, image);
      });
    }

    images.forEach((image, index) => {
      formData.append(`imagens`, image);
    });

    console.log(typeof titulosPremiados);
    console.log(titulosPremiados);

    titulosPremiados?.forEach((item: any, index: any) => {
      formData.append(`cotasPremiadas[${index}][nome]`, item.nome);
      formData.append(`cotasPremiadas[${index}][titulo]`, item.titulo);
      if (item.status) {
        formData.append(`cotasPremiadas[${index}][status]`, item.status);
      } else {
        formData.append(`cotasPremiadas[${index}][status]`, 'liberado');
      }
      if (item.comprador) {
        formData.append(
          `cotasPremiadas[${index}][comprador][nome]`,
          item.comprador?.nome,
        );
        formData.append(
          `cotasPremiadas[${index}][comprador][email]`,
          item.comprador?.email,
        );
        formData.append(
          `cotasPremiadas[${index}][comprador][telefone]`,
          item.comprador?.telefone,
        );
      }
    });

    formData.append('afiliados', String(permiteAfiliados));

    if (antiSpam) {
      formData.append('antiSpam[limiteReservas]', limiteReservas);
      formData.append('antiSpam[tempoBan]', tempoBan);
      formData.append('antiSpam[ativado]', 'true');
    } else {
      formData.append('antiSpam[ativado]', 'false');
    }

    premios.forEach((premio: any, index: number) => {
      formData.append(`premios[${index}][titulo]`, premio.titulo);
    });

    formData.append('prioridade', prioridade);

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .put('/raffles/editRaffle', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        window.location.href = '/dashboard/sorteios';
      })
      .catch((error) => {
        setEnableEditButton(true);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        } else {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO`,
            text: `${error.response.data.errors[0].msg}`,
          });
        }
      });
  };

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: '' };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role);

      if (decodeJwt.role == 'suporte') {
        setAutorizacao(false);
        return;
      }

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
    getClientInfo();
  }, []);

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col bg-orange-50 w-full max-h-screen h-screen max-w-full">
            <div className="flex mt-1 flex-col w-full h-10 max-w-full max-h-full justify-center">
              <button
                onClick={() => navigate('/')}
                className="flex flex-row ml-5 w-fit hover:shadow-inner rounded-lg"
              >
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
            </div>
            <div className="flex flex-row w-full max-h-full p-2">
              <div className="hidden lg:block min-w-fit max-h-full overflow-x-hidden overflow-y-scroll">
                <DashboardSideTools page="sorteios" role={userRole} />
              </div>
              <div className="flex flex-col w-full max-h-screen p-1">
                <div className="my-1 flex flex-col p-2 w-full min-h-40 max-h-40 bg-white rounded-lg shadow-md items-center justify-center">
                  <h1 className="montserrat-500">Preview do sorteio</h1>
                  <div className="flex flex-row w-full">
                    {images.length == 0 ? (
                      <>
                        <img
                          className="large-thumb-photo rounded-lg shadow-md"
                          src={oldImages[0]}
                          alt="sorteio"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="large-thumb-photo rounded-lg shadow-md"
                          src={URL.createObjectURL(images[0])}
                          alt="sorteio"
                        />
                      </>
                    )}
                    <div className="flex flex-row max-w-full max-h-full">
                      <div className="px-2 flex flex-col border-r border-gray-500">
                        <h1 className="montserrat-500">Título: {titulo}</h1>
                        <p key="subtitulo">Sub título: {subTitulo}</p>
                        <p key="link">link: {link}</p>
                        <p key="dataSorteio">
                          Data do sorteio:{' '}
                          {dayjs(dataSorteio)
                            .tz('America/Sao_Paulo')
                            .format('DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                      <div className="px-2 hidden md:flex lg:flex flex-col border-r border-gray-500">
                        <p key="valorU">R$ {valorCotas} por título</p>
                        <p key="qtdCotas">{quantidadeCotas}</p>
                        <p key="qtdMinima">{quantidadeMinima}</p>
                        <p key="qtdMaxima">{quantidadeMaxima}</p>
                      </div>
                      <div className="px-2 hidden md:flex lg:flex flex-col border-r border-gray-500">
                        <p>Prêmios (Principal em primeiro):</p>
                        {premios?.map((premio: any, index: number) => (
                          <p key={`premio-${index}`}>{premio.titulo}</p>
                        ))}
                      </div>
                      <div className="px-2 hidden md:flex lg:flex flex-col">
                        <p key="banco">Banco:</p>
                        <p key="bancoKey">{banco}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-1 flex flex-col p-1 w-full min-h-20 h-20 justify-center items-center bg-white rounded-lg shadow-md">
                  <h1 className="text-orange-700 montserrat-500 text-2xl">
                    Editar sorteio
                  </h1>
                  <div className="flex flex-row justify-between w-full p-1">
                    <Hotbar buttons={buttons} onSelect={setSelectedButton} />
                  </div>
                </div>
                <div className="my-1 flex flex-col p-1 w-full h-full max-h-full bg-white rounded-lg shadow-md">
                  <div className="flex w-full h-full max-h-screen lg:max-h-96 overflow-y-scroll">
                    {renderFormContent()}
                  </div>
                  <div className="flex w-full p-2 justify-end items-end">
                    {enableEditButton ? (
                      <>
                        <button
                          className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-700 transition-all duration-200 ease-in-out"
                          onClick={() => saveChanges()}
                        >
                          Editar sorteio
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="bg-green-700 text-white p-2 rounded-lg">
                          Editando sorteio...
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
