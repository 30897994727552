import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import SingleRaffle from "./components/SingleRaffle";
import Sorteios from "./components/Sorteios";
import NewRaffle from "./components/NewRaffle";
import EditRaffle from "./components/EditRaffle";
import Users from "./components/Users";
import Financeiro from "./components/Financeiro";
import RedesSociais from "./components/RedesSociais";
import TitulosPremiados from "./components/TitulosPremiados";
import NewWinner from "./components/NewWinner";
import Faq from "./components/FAQ";
import Configuracoes from "./components/Configuracoes";
import Certificado from "./components/Certificado";
import Termos from "./components/Termos";
import MyRaffles from "./components/MyRaffles";
import Checkout from "./components/Checkout";
import Perguntas from "./components/Perguntas";
import TermosDeUso from "./components/TermosDeUso";
import Login from "./components/Login";
import Session from "./components/session";
import MercadoPago from "./components/mercadopago";
import AllOrders from "./components/AllOrders";
import AllWinners from "./components/AllWinners";
import Afiliados from "./components/Afiliados";
import AfiliadosControl from "./components/AfiliadosControl";
import OrdersByRaffle from "./components/OrdersByRaffle";
import Rastreadores from "./components/Rastreadores";
import RastreioPorRifa from "./components/RastreioPorRifa";
import Acessos from "./components/Acessos";

export default function Routers(){
    return(
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/singleraffle/:id" element={<SingleRaffle />} />
            <Route path="/dashboard/sorteios" element={<Sorteios />} />
            <Route path="/dashboard/newraffle" element={<NewRaffle />} />
            <Route path="/dashboard/editraffle/:id" element={<EditRaffle />} />
            <Route path="/dashboard/usuarios" element={<Users />} />
            <Route path="/dashboard/financeiro" element={<Financeiro />} />
            <Route path="/dashboard/redessociais" element={<RedesSociais />} />
            <Route path="/dashboard/sorteios/titulospremiados/:id" element={<TitulosPremiados />} />
            <Route path="/dashboard/sorteios/newwinner/:id" element={<NewWinner />} />
            <Route path="/dashboard/sorteios/ordersbyraffle/:id" element={<OrdersByRaffle />} />
            <Route path="/dashboard/faq" element={<Faq />} />
            <Route path="/dashboard/configuracoes" element={<Configuracoes />} />
            <Route path="/dashboard/acessos" element={<Acessos />} />
            <Route path="/dashboard/certificado" element={<Certificado />} />
            <Route path="/dashboard/termos" element={<Termos />} />
            <Route path="/dashboard/allorders" element={<AllOrders />} />
            <Route path="/dashboard/painelafiliados" element={<AfiliadosControl />} />
            <Route path="/dashboard/rastreadores" element={<Rastreadores />} />
            <Route path="/dashboard/rastreadores/:id" element={<RastreioPorRifa />} />
            <Route path="/meusnumeros" element={<MyRaffles />} />
            <Route path="/checkout/:id" element={<Checkout />} />
            <Route path="/faq" element={<Perguntas />} />
            <Route path="/termosdeuso" element={<TermosDeUso />} />
            <Route path="/login" element={<Login />} />
            <Route path="/session" element={<Session />} />
            <Route path="/mercadopago" element={<MercadoPago />} />
            <Route path="/allwinners" element={<AllWinners />} />
            <Route path="/afiliados" element={<Afiliados />} />
        </Routes>
    )
}
