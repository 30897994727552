import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Tools from '../Dashboard/components/Tools';
import { Button } from '@mui/material';

export default function DashboardDrawer({
  isOpen,
  setIsOpen,
  page,
  nivel,
  role
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  page: any;
  nivel: any;
  role: any
}) {
  const toggleDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="top"
        open={isOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            width: '100vw',
            height: '100vh',
            backgroundColor: 'white',
            position: 'relative',
          },
        }}
      >
        <Button
          onClick={toggleDrawer}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '6px',
            color: 'white',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.3em"
            height="2.3em"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="black"
              strokeDasharray="16"
              strokeDashoffset="16"
              strokeLinecap="round"
              strokeWidth="1.95"
            >
              <path d="M7 7L17 17">
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="16;0"
                />
              </path>
              <path d="M17 7L7 17">
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  begin="0.3s"
                  dur="0.3s"
                  values="16;0"
                />
              </path>
            </g>
          </svg>
        </Button>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'black',
          }}
        >
          <Tools page={page} nivel={nivel} role={role} />
        </Box>
      </Drawer>
    </>
  );
}
