/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import api from '../generalComponents/api';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

//accessToken: 'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557'

export default function Rastreadores() {
  const navigate = useNavigate();
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [tracks, setTracks] = useState([] as object[]);
  const [autorizacao, setAutorizacao] = useState(false);
  const [filteredTracks, setFilteredTracks] = useState([] as object[]);
  const [userRole, setUserRole] = useState('');
  // controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: '' };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  useEffect(() => {
    handleGetAllTracks();
  }, []);

  const handleGetAllTracks = async () => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/tracks/getAllTracks', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        console.log(response.data);
        setTracks(response.data);
        setFilteredTracks(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleDeleteTrack = async (trackId: string) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não será capaz de reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
    }).then(async (result) => {
      if (!result.isConfirmed) {
        return;
      } else {
        if (typeof document == 'undefined') {
          return;
        }
        if (typeof window == 'undefined') {
          return;
        }

        const domain = window.location.hostname;

        const response = await api
          .get('/cookie')
          .catch((error) => console.log(error));
        const _csrf = response?.data?.csrfToken;
        api.defaults.headers.common['X-CSRF-Token'] = _csrf;

        await api
          .delete('/tracks/deleteTrack', {
            params: {
              dominio: domain,
              accessToken:
                'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
              id: trackId,
            },
          })
          .then((response) => {
            console.log(response.data);
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Rastreador deletado com sucesso',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          });
      }
    });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermoPesquisa(event.target.value);
    setFilteredTracks(filterTrack(event.target.value));
  };

  const filterTrack = (term: string) => {
    return tracks.filter((track: any) => {
      return track.id.toLowerCase().includes(term.toLowerCase());
    });
  };

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'Link copiado',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="rastreadores"
                nivel={'1'}
                role={userRole}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="rastreadores" role={userRole} />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen bg-white rounded-lg shadow-lg">
                <div className="flex flex-col w-full max-w-full">
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                    className="mr-2"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Pesquisar pelo código de rastreio"
                      variant="outlined"
                      color="success"
                      value={termoPesquisa}
                      onChange={handleFilter}
                    />
                  </Box>
                </div>
                <div className="flex flex-col w-full h-full p-2 overflow-y-scroll">
                  {Array.isArray(filteredTracks) &&
                    filteredTracks?.map((track: any) =>
                      track?.trackId != '' &&
                      track?.trackId != null &&
                      track?.trackId != undefined ? (
                        <>
                          <div
                            className="flex flex-row bg-gray-100 justify-between w-full p-3 my-1 rounded-xl"
                            key={track.trackId}
                          >
                            <div className="flex flex-col">
                              <span>{track.trackId}</span>
                              <span className="hidden md:block lg:block">{`https://${track.dominio}/singleraffle/${track.link}?rastreio=${track.trackId}`}</span>
                              <button
                                onClick={() =>
                                  handleCopyLink(
                                    `https://${track.dominio}/singleraffle/${track.link}?rastreio=${track.trackId}`,
                                  )
                                }
                                className="w-fit h-fit px-4 py-2 rounded-lg text-white bg-green-500 hover:bg-green-600 transition ease-in-out"
                              >
                                Copiar link
                              </button>
                            </div>
                            <div className="flex flex-col">
                              <span>
                                Faturamento: R${' '}
                                {parseFloat(track.valor).toFixed(2)}
                              </span>
                              <span>Números vendidos: {track.quantidade}</span>
                              <button
                                onClick={() => handleDeleteTrack(track.trackId)}
                                className="bg-red-600 text-white font-medium p-2 rounded-lg hover:bg-red-700 transition ease-in-out"
                              >
                                Deletar rastreador
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null,
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
