import { useState } from 'react';
import HeaderMain from '../generalComponents/HeaderMain';
import { Masks } from '../Masks/masks';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import api from '../generalComponents/api';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const masks = new Masks();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState("")
  const [isRegistered, setIsRegistered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [checked, setChecked] = useState(false)
  //states auxiliares
  const [enableLoginButton, setEnableLoginButton] = useState(true);

  const unformatPhoneNumber = (formattedPhoneNumber: string): string => {
    return formattedPhoneNumber.replace(/\D/g, '');
  };

  const handleLogin = async () => {
    setEnableLoginButton(false);
    if (isVisible) {
      handleCadastro();
    }

    if (telefone === '' || telefone.length < 11) {
      Swal.fire({
        title: 'Erro',
        text: 'Digite seu telefone completo',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#32a852',
      });
      return;
    }

    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/buyers/getAccount', {
        params: { dominio: domain, telefone: unformatPhoneNumber(telefone) },
      })
      .then((response) => {
        // USUÁRIO ENCONTRADO
        setNome(response.data.nome);
        setEmail(response.data.email);
        if (
          response.data.afiliado != null &&
          response.data.afiliado != '' &&
          response.data.afiliado != undefined
        ) {
          window.localStorage.setItem(
            'buyer',
            JSON.stringify({
              nome: response.data.nome,
              email: response.data.email,
              telefone: response.data.telefone,
              dominio: response.data.dominio,
              id: response.data.id,
              afiliado: response.data.afiliado,
              cpf: response.data.cpf,
            }),
          );
        } else {
          window.localStorage.setItem(
            'buyer',
            JSON.stringify({
              nome: response.data.nome,
              email: response.data.email,
              telefone: response.data.telefone,
              dominio: response.data.dominio,
              id: response.data.id,
              afiliado: "",
              cpf: response.data.cpf,
            }),
          );
        }

        navigate('/');
        return;
      })
      .catch((erro) => {
        setIsVisible(true);
        setEnableLoginButton(true);
        return;
      });
  };

  const isCpfValid = (value: string) => {
    // Remove os caracteres não numéricos
    value = value.replace(/[^\d]+/g, '');

    // Verifica se o CPF tem 11 dígitos
    if (value.length !== 11) {
      return false;
    }

    // Verifica se todos os números são iguais (não são CPFs válidos)
    if (/^(\d)\1{10}$/.test(value)) {
      return false;
    }

    // Validação dos dois dígitos verificadores
    let soma = 0;
    let resto;

    // Valida o primeiro dígito verificador
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(value.charAt(i - 1)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(value.charAt(9))) {
      return false;
    }

    soma = 0;
    // Valida o segundo dígito verificador
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(value.charAt(i - 1)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(value.charAt(10))) {
      return false;
    }

    return true;
  }

  const handleCheckBox = () => {

  }

  const handleCadastro = async () => {
    if (!checked) {
      Swal.fire({
        title: 'Erro',
        text: 'Confirme que você possui mais de 16 anos de idade.',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#32a852',
      });
      return;
    }

    if (nome === '' || email === '' || telefone === '') {
      Swal.fire({
        title: 'Erro',
        text: 'Preencha todos os campos',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#32a852',
      });
      return;
    }
    if (!/\s/.test(nome) || nome.split(' ')[1].length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Por favor, digite seu nome e sobrenome.',
        confirmButtonColor: '#32a852',
      });
      return;
    }

    let verifiedEmail = email;
    //verifica se o email é válido (se não tiver o @gmail.com ou @hotmail.com, por exemplo, não é válido e adiciona automaticamente)
    if (email.indexOf('@') === -1) {
      Swal.fire({
        title: 'Erro',
        text: 'Email inválido!',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#32a852',
      })
      verifiedEmail = email + '@gmail.com';
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEnableLoginButton(true);
      Swal.fire({
      title: 'Erro',
      text: 'Email inválido!',
      icon: 'error',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#32a852',
      });
      return;
    }

    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    // validar cpf
    if (!isCpfValid(cpf)) {
      Swal.fire({
        title: 'Erro',
        text: 'CPF inválido!',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#32a852',
      });
      return;
    }

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      telefone: unformatPhoneNumber(telefone),
      nome,
      email: verifiedEmail,
      dominio: domain,
      cpf: cpf
    };

    await api
      .post('/buyers/newAccount', data)
      .then((response) => {
        window.localStorage.setItem(
          'buyer',
          JSON.stringify({
            nome: nome,
            email: verifiedEmail,
            id: response.data.docId,
            telefone: unformatPhoneNumber(telefone),
            dominio: domain,
            afiliado: '',
            cpf: cpf
          }),
        );
        navigate('/');
        return;
      })
      .catch((error) => {
        setEnableLoginButton(true);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
        return;
      });
  };

  return (
    <>
      <div className="flex flex-col w-full min-h-screen max-w-full max-h-full bg-gray-100">
        <HeaderMain />
        <div className="flex flex-col w-full h-full p-3 justify-center items-center">
          <div className="w-96 p-2 flex flex-col rounded-xl bg-white shadow-sm border justify-center items-center">
            <div className="flex flex-col w-full">
              {isVisible ? (
                <>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '96%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      disabled
                      autoFocus
                      id="outlined-basic"
                      label="Telefone *"
                      variant="outlined"
                      color="success"
                      value={telefone}
                      onChange={(e: any) =>
                        setTelefone(masks.brPhoneNumberMask(e.target.value))
                      }
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '96%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Nome *"
                      variant="outlined"
                      color="success"
                      value={nome}
                      onChange={(e: any) => setNome(e.target.value)}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '96%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Email *"
                      variant="outlined"
                      color="success"
                      type='email'
                      value={email}
                      onChange={(e: any) =>
                        setEmail(masks.emailMask(e.target.value))
                      }
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '96%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Cpf *"
                      variant="outlined"
                      color="success"
                      type='text'
                      value={cpf}
                      onChange={(e: any) =>
                        setCpf(masks.formatBrazilianCPF(e.target.value))
                      }
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '96%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControlLabel required label="Possuo mais de 16 anos de idade." control={
                      <Checkbox color='success' checked={checked} onChange={() => setChecked(!checked)} />
                    } />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '96%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      autoFocus
                      id="outlined-basic"
                      label="Telefone *"
                      variant="outlined"
                      color="success"
                      value={telefone}
                      onChange={(e: any) =>
                        setTelefone(masks.brPhoneNumberMask(e.target.value))
                      }
                    />
                  </Box>
                </>
              )}
            </div>
            {enableLoginButton ? (
              <>
                <button
                  onClick={() => handleLogin()}
                  className="w-fit h-fit py-2 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 transition ease-in-out"
                >
                  Entrar
                </button>
              </>
            ) : (
              <>
                <button className="w-fit h-fit flex flex-row py-2 px-6 bg-green-600 text-white font-medium rounded-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                      opacity="0.5"
                    />
                    <path
                      fill="white"
                      d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                    >
                      <animateTransform
                        attributeName="transform"
                        dur="0.75s"
                        from="0 12 12"
                        repeatCount="indefinite"
                        to="360 12 12"
                        type="rotate"
                      />
                    </path>
                  </svg>
                  Entrar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
