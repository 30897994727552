import { useEffect, useState } from 'react';
import InfoFaturamento from '../Dashboard/components/InfoFaturamento';
import Profile from '../Dashboard/components/Profile';
import Tools from '../Dashboard/components/Tools';
import api from './api';
import Swal from 'sweetalert2';

export default function DashboardSideTools({ page, role }: any) {
  const [user, setUser] = useState({} as any);

  useEffect(() => {
    const getInfo = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/client/getAccountData', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getInfo();
  }, []);

  return (
    <>
      <div className="flex flex-col min-w-tools">
        {user?.nome ? (
          <>
            <Profile user={user} />
            <InfoFaturamento user={user} />
            <Tools page={page} nivel={user?.nivel} role={role} />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
