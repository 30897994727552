import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import ImgDropzone from '../NewRaffle/components/ImgDropzone';
import { Box, TextField } from '@mui/material';
import { Masks } from '../Masks/masks';
import api from '../generalComponents/api';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Configuracoes() {
  const navigate = useNavigate();
  const masks = new Masks();

  const [nomeSite, setNomeSite] = useState('');
  const [descricaoSite, setDescricaoSite] = useState('');
  const [telefoneSuporte, setTelefoneSuporte] = useState('');
  const [emailSuporte, setEmailSuporte] = useState('');
  const [googleAnalytics, setGoogleAnalytics] = useState('');
  const [facebookPixel, setFacebookPixel] = useState('');
  const [tokenFacebookAPI, setTokenFacebookAPI] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [images, setImages] = useState([] as File[]);
  const [oldImages, setOldImages] = useState([] as any);
  const [old, setOld] = useState(false);
  const [autorizacao, setAutorizacao] = useState(false);
  //configuração do rodapé para capitalizadora
  const [rodapeMessage, setRodapeMessage] = useState('');
  const [logoCapitalizadora, setLogoCapitalizadora] = useState([] as File[]);
  const [oldLogoCapitalizadora, setOldLogoCapitalizadora] = useState([] as any);
  const [logoBeneficiaria, setLogoBeneficiaria] = useState([] as File[]);
  const [oldLogoBeneficiaria, setOldLogoBeneficiaria] = useState([] as any);
  const [oldCapitalizadora, setOldCapitalizadora] = useState(false);
  const [oldBeneficiaria, setOldBeneficiaria] = useState(false);
  //states auxiliares
  const [enableButton, setEnableButton] = useState(true);
  const [palavra, setPalavra] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [starkbankId, setStarkBankId] = useState('');
  const [workspaceId, setWorkspaceId] = useState('');
  const [userRole, setUserRole] = useState('');
  // controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    const getConfig = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/client/getAccountConfig', { params: { dominio: domain } })
        .then((response: any) => {
          setNomeSite(response.data.nomeSite);
          setDescricaoSite(response.data.descricaoSite);
          setTelefoneSuporte(response.data.telSuporte);
          setEmailSuporte(response.data.emailSuporte);
          setGoogleAnalytics(response.data.analytics.googleAnalytics);
          setFacebookPixel(response.data.analytics.pixelID);
          setTokenFacebookAPI(response.data.analytics.tokenApi);
          setWhatsapp(response.data.redesSociais.whatsapp);
          setInstagram(response.data.redesSociais.instagram);
          setFacebook(response.data.redesSociais.facebook);
          setTwitter(response.data.redesSociais.twitter);
          setRodapeMessage(response.data.rodape.texto);
          oldImages.push(response.data.logo);
          oldLogoCapitalizadora.push(response.data.rodape.logoCapitalizadora);
          oldLogoBeneficiaria.push(response.data.rodape.logoBeneficiario);
          if (response.data.logo != 'vazio') setOld(true);
          if (response.data.rodape.logoCapitalizadora != 'vazio') setOldCapitalizadora(true);
          if (response.data.rodape.logoBeneficiario != 'vazio') setOldBeneficiaria(true);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    const getBanks = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/client/getAccountBanks', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response: any) => {
          setClientId(response.data.pay2m.clientId);
          setClientSecret(response.data.pay2m.clientSecret);
          setStarkBankId(response.data.starkbank.id);
          setWorkspaceId(response.data.starkbank.workspaceId);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getConfig();
    getBanks();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: "" };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role)

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  const handleUpdateSocial = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const redesSociais = {
      whatsapp: whatsapp,
      instagram: instagram,
      facebook: facebook,
      twitter: twitter,
    };

    await api
      .put('/client/updateSocial', {
        redesSociais,
        dominio: domain,
        accessToken:
          'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
      })
      .then((response) => {
        console.log('Redes sociais atualizadas com sucesso!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveChanges = async () => {
    setEnableButton(false);
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const formData = new FormData();

    formData.append('dominio', domain);
    formData.append(
      'accessToken',
      'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    );
    formData.append('nomeSite', nomeSite);
    formData.append('descricaoSite', descricaoSite);
    formData.append('telSuporte', telefoneSuporte);
    formData.append('emailSuporte', emailSuporte);
    formData.append('googleAnalytics', googleAnalytics);
    formData.append('pixelId', facebookPixel);
    formData.append('tokenApi', tokenFacebookAPI);

    formData.append('clientId', clientId);
    formData.append('clientSecret', clientSecret);

    formData.append('starkbankId', starkbankId);
    formData.append('workspaceId', workspaceId);
    formData.append('rodapeMessage', rodapeMessage);

    if (images.length != 0) {
      images.forEach((image, index) => {
        formData.append('imagens', image);
      });
    } else {
      formData.append('oldLogo', oldImages);
    }

    if (logoCapitalizadora.length != 0) {
      logoCapitalizadora.forEach((image, index) => {
        formData.append('logoCapitalizadora', image);
      });
    } else {
      formData.append('oldLogoCapitalizadora', oldLogoCapitalizadora);
    }

    if (logoBeneficiaria.length != 0) {
      logoBeneficiaria.forEach((image, index) => {
        formData.append('logoBeneficiaria', image);
      });
    } else {
      formData.append('oldLogoBeneficiaria', oldLogoBeneficiaria);
    }

    handleUpdateSocial();
    await api
      .put('/client/updateConfig', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        location.reload();
        setEnableButton(true);
      })
      .catch((error) => {
        setEnableButton(true);
        if (error.response.data.code) {
          console.log(error)
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const gerarPalavraAleat = () => {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
      return;
    }
    const word =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    setPalavra(word);
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="configuracoes"
                nivel={'1'}
                role={userRole}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="configuracoes" role={userRole} />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <div className="flex text-3xl montserrat-500 p-1 w-full justify-center">
                  Configurações
                </div>
                <div className="flex flex-col w-full">
                  {userRole != "suporte" ?
                    <>
                      <div className="flex flex-col p-2 my-2">
                        <div className="p-1 flex flex-col w-full h-full">
                          <div className="text-xl w-full text-center mb-2">
                            Envie sua logo aqui!
                          </div>
                          <ImgDropzone
                            images={images}
                            setImages={setImages}
                            setOldImages={setOldImages}
                            oldImages={oldImages}
                            old={old}
                          />
                        </div>
                      </div>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Nome do site *"
                          variant="outlined"
                          color="success"
                          value={nomeSite}
                          onChange={(e) => setNomeSite(e.target.value)}
                        />
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Descrição do site *"
                          variant="outlined"
                          color="success"
                          value={descricaoSite}
                          onChange={(e) => setDescricaoSite(e.target.value)}
                        />
                      </Box>
                    </> :
                    <>
                    </>}
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Link Whatsapp (Grupo ou contato) *"
                      variant="outlined"
                      color="success"
                      value={whatsapp}
                      onChange={(e) => setWhatsapp(e.target.value)}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Instagram *"
                      variant="outlined"
                      color="success"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Twitter *"
                      variant="outlined"
                      color="success"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Facebook *"
                      variant="outlined"
                      color="success"
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Telefone para suporte *"
                      variant="outlined"
                      color="success"
                      value={telefoneSuporte}
                      onChange={(e) =>
                        setTelefoneSuporte(
                          masks.brPhoneNumberMask(e.target.value),
                        )
                      }
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Email para suporte *"
                      variant="outlined"
                      type="email"
                      color="success"
                      value={emailSuporte}
                      onChange={(e) =>
                        setEmailSuporte(masks.emailMask(e.target.value))
                      }
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Google Tag Manager (GTM)"
                      variant="outlined"
                      placeholder="GTM-"
                      color="success"
                      value={googleAnalytics}
                      onChange={(e) => setGoogleAnalytics(e.target.value)}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Facebook Pixel ID"
                      variant="outlined"
                      color="success"
                      value={facebookPixel}
                      onChange={(e) =>
                        setFacebookPixel(
                          masks.removeNonNumericChars(e.target.value),
                        )
                      }
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Token da API de conversões do Facebook"
                      variant="outlined"
                      color="success"
                      value={tokenFacebookAPI}
                      onChange={(e) => setTokenFacebookAPI(e.target.value)}
                    />
                  </Box>
                  {userRole == "admin" ?
                    <>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Pay2m Client_Id"
                          variant="outlined"
                          color="success"
                          value={clientId}
                          onChange={(e) => setClientId(e.target.value)}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Pay2m Client_Secret"
                          variant="outlined"
                          color="success"
                          value={clientSecret}
                          onChange={(e) => setClientSecret(e.target.value)}
                        />
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="StarkBank Id"
                          variant="outlined"
                          color="success"
                          value={starkbankId}
                          onChange={(e) => setStarkBankId(e.target.value)}
                        />
                        <TextField
                          id="outlined-basic"
                          label="StarkBank Workspace Id"
                          variant="outlined"
                          color="success"
                          value={workspaceId}
                          onChange={(e) => setWorkspaceId(e.target.value)}
                        />
                      </Box>
                    </> : <></>}
                  <div className="flex flex-col p-2 my-2">
                    <div className="p-1 flex flex-col w-full h-full">
                      <div className="text-xl w-full text-center mb-2">
                        Envie sua logo para o rodapé da capitalizadora
                      </div>
                      <ImgDropzone
                        images={logoCapitalizadora}
                        setImages={setLogoCapitalizadora}
                        setOldImages={setOldLogoCapitalizadora}
                        oldImages={oldLogoCapitalizadora}
                        old={oldCapitalizadora}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col p-2 my-2">
                    <div className="p-1 flex flex-col w-full h-full">
                      <div className="text-xl w-full text-center mb-2">
                        Envie sua logo para o rodapé da beneficiária
                      </div>
                      <ImgDropzone
                        images={logoBeneficiaria}
                        setImages={setLogoBeneficiaria}
                        setOldImages={setOldLogoBeneficiaria}
                        oldImages={oldLogoBeneficiaria}
                        old={oldBeneficiaria}
                      />
                    </div>
                  </div>
                  <div className="max-h-full w-full p-2">
                    <h1 className="my-1">Texto para rodapé do site</h1>
                    <ReactQuill
                      theme="snow"
                      value={rodapeMessage}
                      onChange={(e) => setRodapeMessage(e)}
                      placeholder='Escreva aqui o texto para o rodapé do site'
                    />
                  </div>
                  {userRole == "admin" ?
                    <>
                      <button
                        className="p-2 w-full bg-blue-500 text-white montserrat-500 text-xl rounded-xl my-1 hover:bg-blue-600 transition ease-in-out"
                        onClick={gerarPalavraAleat}
                      >
                        <a
                          target="_blank"
                          href={`https://auth.mercadopago.com/authorization?client_id=8358999037779347&response_type=code&platform_id=mp&state=${palavra}&redirect_uri=https://${location.hostname}/mercadopago`}
                        >
                          Conectar mercado pago
                        </a>
                      </button>
                    </> :
                    <>
                    </>}
                  {enableButton ? (
                    <>
                      <button
                        className="p-2 w-full bg-green-500 text-white montserrat-500 text-xl rounded-xl my-1 hover:bg-green-600 transition ease-in-out"
                        onClick={() => saveChanges()}
                      >
                        Salvar
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="p-2 w-full flex flex-row justify-center items-center bg-green-600 text-white montserrat-500 text-xl rounded-xl my-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="2em"
                          height="2em"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="white"
                            d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                            opacity="0.5"
                          />
                          <path
                            fill="white"
                            d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                          >
                            <animateTransform
                              attributeName="transform"
                              dur="0.75s"
                              from="0 12 12"
                              repeatCount="indefinite"
                              to="360 12 12"
                              type="rotate"
                            />
                          </path>
                        </svg>
                        Salvar
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
