import HeaderMain from '../generalComponents/HeaderMain';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../generalComponents/api';
import Swal from 'sweetalert2';
import RaffleCardAfiliados from './components/raffleCardAfiliados';

export default function Afiliados() {
  const navigate = useNavigate();

  const [afiliadoCode, setAfiliadoCode] = useState('');
  const [dominio, setDominio] = useState('');
  const [faturamentoTotal, setFaturamentoTotal] = useState('0');
  const [vendasTotal, setVendasTotal] = useState('0');
  const [isLogged, setIsLogged] = useState(false);
  const [allRaffles, setAllRaffles] = useState([] as any);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    getAllRaffles();
    setDominio(window.location.hostname);
    const buyer = localStorage.getItem('buyer');
    if (buyer == null || buyer == '' || buyer == undefined) {
      setIsLogged(false);
      return;
    } else {
      setIsLogged(true);
      const buyerObj = JSON.parse(buyer);
      if (buyerObj?.afiliado != '') {
        setAfiliadoCode(buyerObj?.afiliado);
        handleGetAfiliado(buyerObj?.afiliado);
      } else {
        handleNewAfiliado();
      }
    }
  }, []);

  const handleGetAfiliado = async (afiliado: string) => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/buyers/getAfiliado', {
        params: { dominio: domain, afiliado: afiliado },
      })
      .then((response) => {
        setFaturamentoTotal(response.data.faturamento);
        setVendasTotal(response.data.totalVendas);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewAfiliado = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const buyer = JSON.parse(localStorage.getItem('buyer') as string);
    const telefone = buyer?.telefone;

    await api
      .put('/buyers/newAfiliado', {
        dominio: domain,
        telefone: telefone,
        accessToken:
          'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
      })
      .then((response) => {
        console.log(response.data);
        setAfiliadoCode(response.data.afiliado);
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'Afiliado criado com sucesso!',
          text: `Faça login novamente para acessar a página de afiliados!`,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          confirmButtonColor: '#10B981',
        }).then((response) => {
          if (response.isConfirmed) {
            navigate('/login');
          }
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code) {
          if (error.response.data.code != 7001) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        }
      });
  };

  const getAllRaffles = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const response2 = await api
      .get('/raffles/getAllRaffles', { params: { dominio: domain } })
      .then((response) => {
        console.log(response.data);
        setAllRaffles(response.data);
      })
      .catch((error) => {
        if (error.response.data.code) {
          if (error.response.data.code != 7001) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        }
      });
  };

  return (
    <div className="flex flex-col w-full min-h-screen max-h-screen bg-gray-200">
      <HeaderMain />
      <div className="flex flex-col w-full h-screen justify-center items-center p-2">
        <div className="flex flex-col bg-white rounded-xl p-2 w-full md:w-2/3 lg:w-2/3 h-full max-h-full justify-center items-center text-center">
          <h1 className="text-2xl montserrat-500">Painel de afiliado</h1>
          {isLogged ? (
            <>
              <div className="flex flex-col w-full h-full justify-between items-center text-center mt-3">
                <div className="w-1/2 h-fit max-w-1/2 flex flex-col bg-green-200 rounded-xl border border-green-600 p-2 mb-3">
                  <h1>Seu código de afiliado:</h1>
                  <h1 className="text-xs lg:text-lg montserrat-600">{afiliadoCode}</h1>
                </div>
                <div className="my-3 w-1/2 bg-emerald-900 rounded-xl border border-emerald-300 p-2 justify-center items-center text-center">
                  <h1 className="text-white">Total de títulos vendidas:</h1>
                  <h1 className="text-3xl text-white font-bold">
                    {vendasTotal}
                  </h1>
                  <h1 className="text-white">Faturamento:</h1>
                  <h1 className="text-3xl text-white font-bold">
                    R$ {parseFloat(faturamentoTotal).toFixed(2)}
                  </h1>
                </div>
                <div className="w-full bg-gray-300 rounded-xl justify-center items-center p-2 mt-3">
                  <h1 className="font-medium my-1">
                    Campanhas disponíveis para afiliados:
                  </h1>
                  <div className="w-full h-48 max-h-48 overflow-y-auto">
                    {allRaffles?.map((raffle: any, index: number) =>
                      raffle.afiliados == 'true' || raffle.afiliados == true ? (
                        <RaffleCardAfiliados
                          key={index}
                          raffle={raffle}
                          dominio={dominio}
                          afiliadoCode={afiliadoCode}
                        />
                      ) : null,
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col my-10 justify-center items-center">
                <h1 className="text-lg">
                  Você precisa estar logado para acessar a página de afiliados!
                </h1>
                <button
                  onClick={() => navigate('/login')}
                  className="bg-green-500 text-white font-medium rounded-xl w-fit h-fit px-10 py-2 mt-2 hover:bg-green-600 transition ease-in-out"
                >
                  Faça login aqui!
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
