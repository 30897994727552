import { useEffect, useState } from 'react';
import FooterDigitalLucky from '../generalComponents/FooterDigitalLucky';
import InfoFaturamento from './components/InfoFaturamento';
import LastOrders from './components/LastOrders';
import Tools from './components/Tools';
import View from './components/View';
import Welcome from './components/Welcome';
import { useNavigate } from 'react-router-dom';
import api from '../generalComponents/api';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState({} as any);
  const [nivel, setNivel] = useState('1');
  const [autorizacao, setAutorizacao] = useState(false);
  const [userRole, setUserRole] = useState('');
  //controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    const getInfo = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/client/getAccountData', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getInfo();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: "" };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role)

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="w-screen max-w-screen max-h-full flex flex-col bg-orange-50">
            <div className="flex mt-1 flex-col w-full max-w-screen h-10 max-w-full max-h-full justify-center">
              <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
                <button onClick={() => navigate('/')} className="flex flex-row">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 21 21"
                    >
                      <path
                        fill="none"
                        stroke="#00662c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                      />
                    </svg>
                  </span>
                  <span className="text-green-700 montserrat-500 m-1">
                    Voltar
                  </span>
                </button>
                <button
                  className="block md:hidden lg:hidden"
                  onClick={() => setIsOpenDrawer(!isOpenDrawer)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.9em"
                    height="1.9em"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="black"
                      d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                    />
                  </svg>
                </button>
                <DashboardDrawer
                  isOpen={isOpenDrawer}
                  setIsOpen={setIsOpenDrawer}
                  page="dashboard"
                  nivel={nivel}
                  role={userRole}
                />
              </div>
            </div>
            <div className="mb-1 p-1 container max-w-full flex flex-row w-full h-full lg:h-56">
              <div className="w-full h-full flex flex-row">
                {user.nome ? (
                  <>
                    <Welcome user={user} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/4">
              {user.nome ? (
                <>
                  <InfoFaturamento user={user} />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="mt-1 mb-2 p-1 max-w-full flex flex-col lg:flex-row w-full h-auto">
              <div className="hidden md:block lg:block w-full lg:w-1/3 px-1">
                <Tools page="dashboard" nivel={user?.nivel} role={userRole} />
              </div>
              <LastOrders />
              <View />
            </div>
          </div>
        </>
      )}
    </>
  );
}
