/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import HeaderMain from '../generalComponents/HeaderMain';
import { useEffect, useState } from 'react';
import { Box, Modal, TextField, Typography } from '@mui/material';
import api from '../generalComponents/api';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';
import dayjs from 'dayjs';

//desconmentar o Profile e o InfoFaturamento depois. Comentei pq não estou com acesso ao back e fica dando erro

export default function Users() {
  const navigate = useNavigate();
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [users, setUsers] = useState([] as object[]);
  const [autorizacao, setAutorizacao] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([] as object[]);
  const [userRole, setUserRole] = useState('');
  //edição de usuário
  const [open, setOpen] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  //controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  //states auxiliares
  const [enableDownloadButton, setEnableDownloadButton] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getBuyers = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/buyers/getAllAccounts', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response) => {
          setUsers(response.data);
          setFilteredUsers(response.data);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getBuyers();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: '' };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermoPesquisa(event.target.value);
    setFilteredUsers(filterUsers(event.target.value));
  };

  const filterUsers = (term: string) => {
    return users.filter((user: any) => {
      return (
        user.nome.toLowerCase().includes(term.toLowerCase()) ||
        user.telefone.includes(term)
      );
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '20px',
  };

  const handleEditUser = (
    nome: string,
    telefone: string,
    email: string,
    id: string,
  ) => {
    setNome(nome);
    setTelefone(telefone);
    setEmail(email);
    setId(id);
  };

  const handleSubmitEditUser = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      nome: nome,
      telefone: telefone,
      email: email,
      dominio: domain,
      id: id,
    };

    await api
      .put('/buyers/editAccount', data)
      .then((response) => {
        console.log(response);
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'Usuário editado com sucesso!',
          showConfirmButton: true,
          confirmButtonColor: '#00662c',
          timer: 1500,
        }).then(() => {
          handleClose();
          window.location.reload();
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: `ERRO - ${error.response.data.code}`,
          text: `${error.response.data.error}`,
        });
      });
  };

  useEffect(() => {
    if (telefone !== '') {
      setOpen(true);
    }
  }, [telefone]);

  const handleExportCsv = async () => {
    console.log('exportando csv');
    setEnableDownloadButton(false);
    if (typeof document == 'undefined' || typeof window == 'undefined') return;

    try {
      const domain = window.location.hostname;

      // Obtém o token CSRF
      const csrfResponse = await api.get('/cookie');
      const _csrf = csrfResponse?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      // Faz a requisição para exportar o CSV
      const response = await api.get('/buyers/exportCSV', {
        params: { dominio: domain },
        responseType: 'blob',
      });

      // Processa o arquivo CSV para download
      const date = dayjs().tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm');
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `usuarios-${date}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Remove o link do DOM após o clique
      window.URL.revokeObjectURL(url); // Revoga a URL após o download
    } catch (error) {
      console.error('Erro ao exportar CSV:', error);
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'ERRO',
        text: "Não foi possível exportar o arquivo CSV. Tente novamente mais tarde.",
      });
    } finally {
      setEnableDownloadButton(true); // Reativa o botão de download
    }
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Editar informações do usuário
              </Typography>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '96%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  autoFocus
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  color="success"
                  value={nome}
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '96%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  autoFocus
                  id="outlined-basic"
                  label="Telefone"
                  variant="outlined"
                  color="success"
                  value={telefone}
                  onChange={(e) => {
                    setTelefone(e.target.value);
                  }}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '96%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  autoFocus
                  id="outlined-basic"
                  label="E-mail"
                  variant="outlined"
                  color="success"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Box>
              <div className="flex w-full items-center justify-around">
                <button
                  onClick={() => handleClose()}
                  className="w-fit h-fit py-2 px-6 bg-red-500 text-white font-medium rounded-xl hover:bg-red-600 transition ease-in-out"
                >
                  Cancelar
                </button>
                <button
                  onClick={() => handleSubmitEditUser()}
                  className="w-fit h-fit py-2 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 transition ease-in-out"
                >
                  Editar
                </button>
              </div>
            </Box>
          </Modal>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="usuarios"
                nivel={'1'}
                role={userRole}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="usuarios" role={userRole} />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen bg-white rounded-lg shadow-lg">
                <div className="flex flex-row w-full px-1 items-center justify-center">
                  <div className="flex flex-row w-full max-w-full">
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '98%' },
                        width: '100%',
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Pesquisar por nome ou telefone"
                        variant="outlined"
                        color="success"
                        value={termoPesquisa}
                        onChange={handleFilter}
                      />
                    </Box>
                  </div>
                  {enableDownloadButton ? (
                    <>
                      <button
                        onClick={() => handleExportCsv()}
                        className="flex flex-row w-fit h-fit p-2 items-center justify-center text-center bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 transition ease-in-out"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 24 24"
                        >
                          <g
                            fill="none"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.95"
                          >
                            <path
                              stroke-dasharray="32"
                              stroke-dashoffset="32"
                              d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9"
                            >
                              <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                dur="0.3s"
                                values="32;0"
                              />
                              <set
                                fill="freeze"
                                attributeName="stroke-dasharray"
                                begin="0.6s"
                                to="2 4"
                              />
                            </path>
                            <path
                              stroke-dasharray="32"
                              stroke-dashoffset="32"
                              d="M12 21c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9"
                            >
                              <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                begin="0.3s"
                                dur="0.3s"
                                values="32;0"
                              />
                            </path>
                            <path
                              stroke-dasharray="10"
                              stroke-dashoffset="10"
                              d="M12 8v7.5"
                            >
                              <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                begin="0.6s"
                                dur="0.15s"
                                values="10;0"
                              />
                            </path>
                            <path
                              stroke-dasharray="6"
                              stroke-dashoffset="6"
                              d="M12 15.5l3.5 -3.5M12 15.5l-3.5 -3.5"
                            >
                              <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                begin="0.75s"
                                dur="0.15s"
                                values="6;0"
                              />
                            </path>
                          </g>
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="flex flex-row w-fit h-fit p-2 items-center justify-center text-center bg-green-600 text-white font-medium rounded-xl">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 24 24"
                        >
                          <g
                            fill="none"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.95"
                          >
                            <path
                              stroke-dasharray="2 4"
                              stroke-dashoffset="6"
                              d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9"
                            >
                              <animate
                                attributeName="stroke-dashoffset"
                                dur="0.45s"
                                repeatCount="indefinite"
                                values="6;0"
                              />
                            </path>
                            <path
                              stroke-dasharray="32"
                              stroke-dashoffset="32"
                              d="M12 21c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9"
                            >
                              <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                begin="0.075s"
                                dur="0.3s"
                                values="32;0"
                              />
                            </path>
                            <path
                              stroke-dasharray="10"
                              stroke-dashoffset="10"
                              d="M12 8v7.5"
                            >
                              <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                begin="0.375s"
                                dur="0.15s"
                                values="10;0"
                              />
                            </path>
                            <path
                              stroke-dasharray="6"
                              stroke-dashoffset="6"
                              d="M12 15.5l3.5 -3.5M12 15.5l-3.5 -3.5"
                            >
                              <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                begin="0.525s"
                                dur="0.15s"
                                values="6;0"
                              />
                            </path>
                          </g>
                        </svg>
                      </button>
                    </>
                  )}
                </div>
                <div className="flex flex-col w-full h-full p-2 overflow-y-scroll">
                  {filteredUsers?.map((user: any) => (
                    <div
                      className="flex flex-row bg-gray-100 justify-between w-full p-3 my-1 rounded-xl"
                      key={user.id}
                    >
                      {
                        <>
                          <div className="flex flex-col text-sm md:flex-row lg:flex-row w-full justify-between">
                            <span>{user.nome}</span>
                            <span>{user.telefone}</span>
                            <span>{user.email}</span>
                            {user?.cpf ? (
                              <span>{user?.cpf}</span>
                            ) : (
                              <>000.000.000-00</>
                            )}
                          </div>
                          {userRole != 'suporte' ?
                            <>
                              <button
                                onClick={(e) =>
                                  handleEditUser(
                                    user.nome,
                                    user.telefone,
                                    user.email,
                                    user.id,
                                  )
                                }
                                className="w-fit h-fit ml-1 rounded-lg bg-blue-600 p-2 hover:bg-blue-700 transition ease-in-out"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1.2em"
                                  height="1.2em"
                                  viewBox="0 0 24 24"
                                >
                                  <g
                                    fill="none"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.95"
                                  >
                                    <path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1" />
                                    <path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3zM16 5l3 3" />
                                  </g>
                                </svg>
                              </button>
                            </> :
                            <>
                            </>}
                        </>
                      }
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
