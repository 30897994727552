import { useEffect, useState } from 'react';
import { Masks } from '../Masks/masks';
import { Box, TextField } from '@mui/material';
import api from '../generalComponents/api';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function NewWinner() {
  const masks = new Masks();
  const navigate = useNavigate();

  //informações da rifa
  const [titulo, setTitulo] = useState('');
  const [dataSorteio, setDataSorteio] = useState('');
  const [imagem, setImagem] = useState([] as any);
  const [premios, setPremios] = useState([] as any);
  const [modalidade, setModalidade] = useState('loteria');
  //informações do ganhador
  const [ganhador, setGanhador] = useState('Sem ganhador definido!');
  const [quantidadeCotas, setQuantidadeCotas] = useState(1000000);
  const [numeroTituloGanhador, setNumeroTituloGanhador] = useState('');
  const [ganhadorInfo, setGanhadorInfo] = useState([] as any);
  const [exibirResultado, setExibirResultado] = useState(false);
  const [exibirTelefone, setExibirTelefone] = useState(false);
  const [autorizacao, setAutorizacao] = useState(false);
  const [selectedPremio, setSelectedPremio] = useState(0);
  //states auxiliares
  const [enableWinnerButton, setEnableWinnerButton] = useState(true);
  const [isGirando, setIsGirando] = useState(false);
  const [numeroGiro, setNumeroGiro] = useState('Boa sorte!');
  const [userRole, setUserRole] = useState('');
  //controlador do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  let cont = 0;

  const handlerSubmitNewWinner = (
    e: React.FormEvent<HTMLButtonElement>,
    numero: string,
  ) => {
    e.preventDefault();
    if (numero.trim() === '') {
      alert('Número do título do ganhador é obrigatório!');
      return;
    }
    setNumeroTituloGanhador(
      masks.setZeroLeft(numero, (quantidadeCotas - 1).toString().length),
    );
    handleNewWinner(numero.trim());
  };

  const handleShowPhone = () => {
    setExibirTelefone(!exibirTelefone);
  };

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: '' };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role);

      if (decodeJwt.role == 'suporte') {
        setAutorizacao(false);
        return;
      }

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
          handleGetRaffle();
          handleGetWinner();
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  useEffect(() => {
    if (cont === 0) {
      if (
        premios[0]?.comprador !== undefined &&
        premios[0]?.comprador !== null &&
        premios[0]?.comprador !== ''
      ) {
        setGanhador(premios[selectedPremio]?.comprador);
        setNumeroTituloGanhador(premios[selectedPremio]?.cota);
        setNumeroGiro(premios[selectedPremio]?.cota);
        setIsGirando(true);
        setExibirResultado(true);
        cont++;
      }
    }
  }, [premios]);

  const handleGetRaffle = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/raffles/getRaffle', {
        params: {
          dominio,
          link: link,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj12312394a8945557',
        },
      })
      .then((response) => {
        setQuantidadeCotas(response.data[0].qtdCotas);
        setTitulo(response.data[0].nome);
        setDataSorteio(
          dayjs(response.data[0].dataSorteio)
            .tz('America/Sao_Paulo')
            .format('DD/MM/YYYY HH:mm'),
        );
        setImagem(response.data[0].imagens);
        setPremios(response.data[0].premios);
        setModalidade(response.data[0]?.modalidade);
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleGetWinner = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/winners/getWinner', {
        params: {
          dominio,
          link: link,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        if (
          response?.data === null ||
          response?.data === undefined ||
          response?.data === ''
        ) {
          setGanhador('Sem ganhador definido!');
          return;
        }
        setGanhador(response?.data?.nome);
        setGanhadorInfo(response?.data);
        setNumeroTituloGanhador(response?.data?.cota);
        setExibirResultado(!exibirResultado);
        setIsGirando(true);
        setNumeroGiro(response?.data?.cota);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewWinner = async (numeroGanhador: string) => {
    setEnableWinnerButton(false);
    const today = dayjs().tz('America/Sao_Paulo').format('DD/MM/YYYY');
    const raffleDate = dayjs(dataSorteio, 'DD/MM/YYYY HH:mm')
      .tz('America/Sao_Paulo')
      .format('DD/MM/YYYY');

    if (today !== raffleDate) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'Erro',
        text: 'A data do sorteio não é igual ao dia atual!',
      });
      setEnableWinnerButton(true);
      return;
    }
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio,
      link: link,
      premio: String(selectedPremio),
      cota: masks.setZeroLeft(
        numeroGanhador,
        (quantidadeCotas - 1).toString().length,
      ),
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    };

    await api
      .post('/winners/newWinner', data)
      .then((response) => {
        setEnableWinnerButton(true);
        setGanhador(response?.data?.nome);
        setNumeroTituloGanhador(response?.data?.cota);
        setGanhadorInfo(response?.data);
        setExibirResultado(!exibirResultado);
        handleGetRaffle();
      })
      .catch((error) => {
        setEnableWinnerButton(true);
        if (error.response.data.code !== 8026) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          }).then((confirmation) => {
            if (confirmation.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: `Esse título não foi comercializado!`,
            text: `Como a venda mínima não foi antendida, o prêmio deve ser da capitalizadora!`,
          });
        }
      });
  };

  const handleDeleteWinner = async () => {
    setEnableWinnerButton(false);
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio,
      link: link,
      premio: selectedPremio,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    };

    await api
      .delete('/winners/deleteWinner', { params: data })
      .then((response) => {
        setEnableWinnerButton(true);
        Swal.fire({
          icon: 'success',
          title: 'Ganhador deletado!',
          text: 'O ganhador foi deletado com sucesso!',
          confirmButtonColor: '#3085d6',
        }).then(() => {
          window.location.reload();
        });
        setGanhador('Sem ganhador definido!');
        setExibirResultado(!exibirResultado);
      })
      .catch((error) => {
        setEnableWinnerButton(true);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleGiroDaSorte = () => {
    const today = dayjs().tz('America/Sao_Paulo').format('DD/MM/YYYY');
    const raffleDate = dayjs(dataSorteio, 'DD/MM/YYYY HH:mm')
      .tz('America/Sao_Paulo')
      .format('DD/MM/YYYY');

    if (today !== raffleDate) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'Erro',
        text: 'A data do sorteio não é igual ao dia atual!',
      });
      return;
    }
    setIsGirando(true); // Inicia a animação de sorteio

    let count = 5;
    const totalIntervals = 40; // Quantidade de vezes que números aleatórios serão exibidos
    const finalTimeout = (totalIntervals + count) * 1000; // Tempo total até o sorteio final

    // Exibe o contador regressivo de 5 a 0
    const interval = setInterval(() => {
      setNumeroGiro(count.toString());
      count--;

      if (count < 0) {
        clearInterval(interval); // Para o contador regressivo

        // Sorteia números aleatórios
        const randomInterval = setInterval(() => {
          setNumeroGiro(Math.floor(Math.random() * quantidadeCotas).toString()); // Atualiza com números aleatórios
        }, 100);

        // Para o sorteio após determinado tempo
        setTimeout(() => {
          clearInterval(randomInterval); // Para os números aleatórios
          // Exibe o número sorteado final
          const numeroFinal = masks.setZeroLeft(
            Math.floor(Math.random() * quantidadeCotas).toString(),
            (quantidadeCotas - 1).toString().length,
          );
          setNumeroGiro(numeroFinal);
          setNumeroTituloGanhador(numeroFinal);
          const syntheticEvent = {
            preventDefault: () => {},
          } as React.FormEvent<HTMLButtonElement>;
          handlerSubmitNewWinner(syntheticEvent, numeroFinal.toString()); // Chama a função de definir novo ganhador
        }, totalIntervals * 100); // Após 10 iterações (100ms cada)
      }
    }, 1000); // Contagem regressiva de 5 a 0
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = parseInt(event.target.value, 10);
    setSelectedPremio(selectedIndex);
    if (
      premios[selectedIndex]?.comprador !== undefined &&
      premios[selectedIndex]?.comprador !== null &&
      premios[selectedIndex]?.comprador !== ''
    ) {
      setIsGirando(true);
      setNumeroGiro(premios[selectedIndex]?.cota);
      setExibirResultado(true);
    } else {
      setIsGirando(false);
      setNumeroGiro('Boa sorte!');
      setExibirResultado(false);
    }
    setGanhador(premios[selectedIndex]?.comprador);
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="sorteios"
                nivel={'1'}
                role={userRole}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="sorteios" role={userRole} />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl w-full text-center">
                  Definir novo ganhador
                </h1>
                {modalidade === 'loteria' ? (
                  <>
                    <p className="p-2 bg-amber-100 rounded-xl text-amber-900">
                      Escreva o número na área correspondente para definir um
                      ganhador. O ganhador somente será definido se esse número
                      já estiver sido comprado! Portanto, antes de definir o
                      ganhador, tenha certeza de que sua ação tenha sido 100%
                      vendida!
                    </p>
                  </>
                ) : (
                  <>
                    <p className="p-2 bg-amber-100 rounded-xl text-amber-900">
                      Clique no botão para GIRAR UM NÚMERO ALEATÓRIO e o sistema
                      definirá, automaticamente, um ganhador para você! <br />
                      <br />O sistema gerará um número dentro do intervalo
                      definido do sorteio! <br />
                      <br />
                      Por exemplo, sorteio de 100 mil títulos: intervalo entre
                      00000 e 99999. <br />
                      <br />
                      Se o número não tiver sido vendido, o sistema informará e
                      você poderá tentar novamente!
                    </p>
                  </>
                )}
                <label
                  htmlFor="premios-select"
                  className="block mb-2 text-sm font-medium"
                >
                  Escolha um prêmio:
                </label>
                <select
                  id="premios-select"
                  value={selectedPremio ?? ''}
                  onChange={(e) => handleSelectChange(e)}
                  className="block w-full p-2 border rounded-lg shadow-md"
                >
                  <option value="" disabled>
                    Selecione um prêmio
                  </option>
                  {premios.map((premio: any, index: number) => (
                    <option key={index} value={index}>
                      {index + 1}° Prêmio: {premio.titulo}
                    </option>
                  ))}
                </select>
                <div className="mt-4 flex flex-col md:flex-row lg:flex-row w-full justify-center items-center lg:justify-start">
                  <img
                    className="border-2 border-gray-300 rounded-xl w-96 h-fit object-contain"
                    src={imagem[0]}
                    alt=""
                  />
                  <div className="w-full flex flex-col p-2 justify-center items-center md:justify-normal md:items-start lg:justify-normal lg:items-start">
                    <h1 className="text-3xl font-medium my-1">{titulo}</h1>
                    <h1 className="my-1">{dataSorteio}</h1>
                    <h1 className="text-2xl my-1 montserrat-500">
                      {premios[selectedPremio] &&
                      premios[selectedPremio].comprador
                        ? premios[selectedPremio].ganhador
                        : ganhador}
                    </h1>
                    {modalidade === 'loteria' ? (
                      <>
                        {premios[selectedPremio] &&
                        premios[selectedPremio].comprador ? (
                          <>
                            {enableWinnerButton ? (
                              <>
                                <div className="w-full flex flex-row items-end justify-end">
                                  <button
                                    onClick={() => handleDeleteWinner()}
                                    className="w-auto p-1 text-red-700 text-xs font-medium bg-white rounded-xl border-red-700 border hover:bg-red-700 hover:text-white transition ease-in-out"
                                  >
                                    Deletar ganhador
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="w-full flex flex-row items-end justify-end">
                                  <button className="w-auto p-1 text-xs text-white font-medium bg-red-700 rounded-xl">
                                    Deletando...
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="w-full flex flex-row items-center">
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { my: 1, width: '98%' },
                                  width: '100%',
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <TextField
                                  id="outlined-basic"
                                  label="Número do título ganhador *"
                                  variant="outlined"
                                  color="success"
                                  value={numeroTituloGanhador}
                                  onChange={(e) =>
                                    setNumeroTituloGanhador(
                                      masks.removeNonNumericChars(
                                        e.target.value,
                                      ),
                                    )
                                  }
                                />
                              </Box>
                              {enableWinnerButton ? (
                                <>
                                  <button
                                    className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600  transition ease-in-out"
                                    onClick={(e) =>
                                      handlerSubmitNewWinner(
                                        e,
                                        numeroTituloGanhador,
                                      )
                                    }
                                  >
                                    Enviar
                                  </button>
                                </>
                              ) : (
                                <>
                                  <>
                                    <button className="w-fit h-fit p-2 bg-green-600 text-white rounded-xl m-2">
                                      Procurando ganhador...
                                    </button>
                                  </>
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {premios[selectedPremio] &&
                        premios[selectedPremio].comprador ? (
                          <>
                            <div className="my-1">
                              Parabéns,{' '}
                              <strong>
                                {premios[selectedPremio] &&
                                premios[selectedPremio].comprador
                                  ? premios[selectedPremio].comprador
                                  : ganhador}
                              </strong>
                              ! Você é o ganhador do sorteio com o título número{' '}
                              <strong>
                                {premios[selectedPremio] &&
                                premios[selectedPremio].cota
                                  ? premios[selectedPremio].cota
                                  : numeroTituloGanhador}
                              </strong>
                              !
                            </div>
                            <button
                              className="my-1 p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-xl"
                              onClick={() => handleShowPhone()}
                            >
                              Mostrar informações pessoais
                            </button>
                            <button
                              onClick={() =>
                                navigate(
                                  `/checkout/${premios[selectedPremio] && premios[selectedPremio].orderId ? premios[selectedPremio].orderId : ganhadorInfo?.orderId}`,
                                )
                              }
                              className="my-1 p-2 bg-green-500 hover:bg-green-600 text-white rounded-xl"
                            >
                              Ir para o pedido (Contém as informações pessoais
                              do ganhador!)
                            </button>
                            {exibirTelefone ? (
                              <div className="my-1">
                                Nome do ganhador:{' '}
                                <strong>
                                  {premios[selectedPremio] &&
                                  premios[selectedPremio].comprador
                                    ? premios[selectedPremio].comprador
                                    : ganhador}
                                </strong>
                                <br />
                                Telefone do ganhador:{' '}
                                <strong>
                                  {premios[selectedPremio] &&
                                  premios[selectedPremio].telefone
                                    ? premios[selectedPremio].telefone
                                    : ganhadorInfo?.telefone}
                                </strong>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </>
                        ) : (
                          <>
                            <div></div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="w-full flex flex-col items-center justify-center text-center p-1">
                          {!isGirando ? (
                            <>
                              <button
                                onClick={() => handleGiroDaSorte()}
                                className="p-2 w-full rounded-lg font-bold text-2xl text-white bg-green-500 hover:bg-green-600 transition ease-in-out"
                              >
                                Giro da sorte!
                              </button>
                            </>
                          ) : (
                            <>
                              <div className="w-full flex flex-col items-center justify-center text-center">
                                <h1 className="p-2 text-3xl">{numeroGiro}</h1>
                                {exibirResultado ? (
                                  <>
                                    <div className="flex flex-col w-full items-start justify-start text-start">
                                      <div className="my-1">
                                        Parabéns,{' '}
                                        <strong>
                                          {premios[selectedPremio] &&
                                          premios[selectedPremio].comprador
                                            ? premios[selectedPremio].comprador
                                            : ganhador}
                                        </strong>
                                        ! Você é o ganhador do sorteio com o
                                        título número{' '}
                                        <strong>
                                          {premios[selectedPremio] &&
                                          premios[selectedPremio].cota
                                            ? premios[selectedPremio].cota
                                            : numeroTituloGanhador}
                                        </strong>
                                        !
                                      </div>
                                      <button
                                        className="my-1 p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-xl"
                                        onClick={() => handleShowPhone()}
                                      >
                                        Mostrar informações pessoais
                                      </button>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/checkout/${premios[selectedPremio] && premios[selectedPremio].orderId ? premios[selectedPremio].orderId : ganhadorInfo?.orderId}`,
                                          )
                                        }
                                        className="my-1 p-2 bg-green-500 hover:bg-green-600 text-white rounded-xl"
                                      >
                                        Ir para o pedido (Contém as informações
                                        pessoais do ganhador!)
                                      </button>
                                      {exibirTelefone ? (
                                        <div className="my-1">
                                          Nome do ganhador:{' '}
                                          <strong>
                                            {premios[selectedPremio] &&
                                            premios[selectedPremio].comprador
                                              ? premios[selectedPremio]
                                                  .comprador
                                              : ganhador}
                                          </strong>
                                          <br />
                                          Telefone do ganhador:{' '}
                                          <strong>
                                            {premios[selectedPremio] &&
                                            premios[selectedPremio].telefone
                                              ? premios[selectedPremio].telefone
                                              : ganhadorInfo?.telefone}
                                          </strong>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
