import React, { useState } from 'react';
import { Masks } from '../../Masks/masks';
import { useNavigate } from 'react-router-dom';
import api from '../../generalComponents/api';
import { Box, Modal, TextField, Typography } from '@mui/material';
import { access } from 'fs';
import Swal from 'sweetalert2';

interface OrderProps {
  index: number;
  order: {
    id: string;
    pedido: string;
    comprador: {
      email: string;
      nome: string;
      telefone: string;
    };
    rifa: {
      afiliado: string;
      banco: string;
      link: string;
      sorteioGratuito: boolean;
      tempoExpiracao: string;
      nome: string;
      imagem: string;
    };
    valor: number;
    data_pedido: string;
    dominio: string;
    expira_em: string;
    orderId: string;
    quantidade: number;
    status: string;
  };
  cota: string;
  role: string;
}

const OrderComplete: React.FC<OrderProps> = ({ index, order, cota, role }) => {
  const [open, setOpen] = useState(false);
  const [oldOrder, setOldOrder] = useState({} as any);
  const [telefone, setTelefone] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');

  const handleTranferirPedido = async (order: any) => {
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;

    const dominio = window.location.hostname;

    const data = {
      pedido: order,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
      telefone,
      dominio,
    };

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .put('/orders/transferOrder', data)
      .then((response) => {
        location.reload();
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleCancelarPedido = async (order: any) => {
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;

    const result = await Swal.fire({
      title: 'Deseja expirar o pedido?',
      showDenyButton: true,
      confirmButtonText: 'Expirar',
      denyButtonText: 'Cancelar',
    });

    if (result.isDenied) {
      return;
    }

    const dominio = window.location.hostname;

    const data = {
      pedido: order,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    };

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .put('/orders/expireOrder', data)
      .then((response) => {
        location.reload();
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleAprovarPedido = async (order: any) => {
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;

    const result = await Swal.fire({
      title: 'Deseja aprovar o pedido?',
      showDenyButton: true,
      confirmButtonText: 'Aprovar',
      denyButtonText: 'Cancelar',
    });

    if (result.isDenied) {
      return;
    }

    const dominio = window.location.hostname;

    const data = {
      pedido: order,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    };

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .put('/orders/approveOrder', data)
      .then((response) => {
        location.reload();
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleDeleteOrder = async (id: any) => {
    if (typeof window === 'undefined') return;
    if (typeof document === 'undefined') return;

    const result = await Swal.fire({
      title: 'Deseja excluir o pedido?',
      showDenyButton: true,
      confirmButtonText: 'Excluir',
      denyButtonText: 'Cancelar',
    });

    if (result.isDenied) {
      return;
    }

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .delete('/orders/deleteOrder', {
        params: {
          id,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        location.reload();
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '20px',
  };

  const masks = new Masks();
  const navigate = useNavigate();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Transferir pedido
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {oldOrder.comprador ? (
              <>
                <div className="flex flex-col items-center">
                  <span className="font-bold text-xs">
                    Telefone: {oldOrder.comprador.telefone}
                  </span>
                  <span className="font-bold text-xs">
                    Nome: {oldOrder.comprador.nome}
                  </span>
                  <span className="font-bold text-xs">
                    Email: {oldOrder.comprador.email}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '96%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                autoFocus
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                color="success"
                value={telefone}
                onChange={(e) => {
                  setTelefone(e.target.value);
                }}
              />
            </Box>
            <div className="flex w-full items-center justify-around">
              <button
                onClick={() => handleClose()}
                className="w-fit h-fit py-2 px-6 bg-red-500 text-white font-medium rounded-xl hover:bg-red-600 transition ease-in-out"
              >
                Cancelar
              </button>
              <button
                onClick={() => handleTranferirPedido(oldOrder.pedido)}
                className="w-fit h-fit py-2 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 transition ease-in-out"
              >
                Transferir
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
      <div
        key={index}
        className="my-1 flex flex-col items-center p-2 border bg-white rounded-xl w-full h-full border-gray-200 drop-shadow-lg"
      >
        <div className="flex flex-col md:flex-row lg:flex-row justify-between w-full">
          <img
            className="small-thumb-photo rounded-lg drop-shadow-lg object-cover cursor-pointer"
            onClick={() => navigate(`/checkout/${order.id}`)}
            src={order.rifa.imagem}
            alt=""
          />
          <div className="mx-0 px-2 md:mx-2 lg:mx-2 md:px-0 lg:px-0 text-start justify-between flex flex-row w-full max-w-full h-full">
            <div
              onClick={() => navigate(`/checkout/${order.id}`)}
              className="flex flex-col cursor-pointer"
            >
              <h1 className="hidden md:block lg:block text-sm text-orange-700 montserrat-500">
                #{order.pedido}
              </h1>
              <h1 className="text-sm">{order.comprador.nome}</h1>
              <h1 className="text-sm">{order.comprador.telefone}</h1>
              <h1 className="text-sm montserrat-600">{order.rifa.nome}</h1>
              {cota != '0' ? (
                <>
                  <h1 className="text-sm montserrat-600">Menor/Maior título: {cota}</h1>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col text-center">
              <h1
                className={`text-sm font-bold rounded-lg ${order.status == 'expirado' || order.status == 'cancelado' ? 'bg-red-300 text-red-600' : order.status == 'pendente' ? 'bg-orange-300 text-orange-600' : 'bg-green-300 text-green-600'}`}
              >
                {order.status}
              </h1>
              <h1 className="text-sm">R$ {order.valor.toFixed(2)}</h1>
              <h1 className="text-sm">
                {masks.formatISODateToBrazilian(order.data_pedido)}
              </h1>
              <a
                href={`https://wa.me/+55${order.comprador.telefone}`}
                target="_blank"
                className="mt-1 text-xs font-bold rounded-lg bg-blue-400 cursor-pointer"
              >
                Conversar
              </a>
              <div className="flex flex-row w-full justify-end text-xs">
                {role == "admin" ?
                  <>
                    <button
                      onClick={() => {
                        handleOpen();
                        setOldOrder(order);
                      }}
                      className="p-2"
                      title="Transferir pedido"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="none"
                          stroke="#e48807"
                          strokeLinecap="round"
                          strokeWidth="1.5"
                        >
                          <path
                            strokeLinejoin="round"
                            d="M17 10H7l3.438-3M7 14h10l-3.437 3"
                          />
                          <path d="M7 3.338A9.954 9.954 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12c0-1.821.487-3.53 1.338-5" />
                        </g>
                      </svg>
                    </button>
                    {order.status == 'aprovado' ? (
                      <>
                        <button
                          onClick={() => handleCancelarPedido(order.pedido)}
                          className="p-2"
                          title="Expirar pedido"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="#e48807"
                              fillRule="evenodd"
                              d="M8.175.002a8 8 0 1 0 2.309 15.603a.75.75 0 0 0-.466-1.426a6.5 6.5 0 1 1 3.996-8.646a.75.75 0 0 0 1.388-.569A8 8 0 0 0 8.175.002M8.75 3.75a.75.75 0 0 0-1.5 0v3.94L5.216 9.723a.75.75 0 1 0 1.06 1.06L8.53 8.53l.22-.22zM15 15a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-.25-6.25a.75.75 0 0 0-1.5 0v3.5a.75.75 0 0 0 1.5 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </>
                    ) : order.status == 'expirado' || order.status == 'cancelado' ? (
                      <></>
                    ) : (
                      <>
                        <button
                          onClick={() => handleAprovarPedido(order.pedido)}
                          className="p-2"
                          title="Aprovar pedido"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                          >
                            <path
                              fill="#e48807"
                              d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0m0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01m204.336-636.352L415.935 626.944l-135.28-135.28c-12.496-12.496-32.752-12.496-45.264 0c-12.496 12.496-12.496 32.752 0 45.248l158.384 158.4c12.496 12.48 32.752 12.48 45.264 0c1.44-1.44 2.673-3.009 3.793-4.64l318.784-320.753c12.48-12.496 12.48-32.752 0-45.263c-12.512-12.496-32.768-12.496-45.28 0"
                            />
                          </svg>
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => {
                        handleDeleteOrder(order.id);
                      }}
                      className="p-2"
                      title="Excluir pedido"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" stroke="#e48807" strokeWidth="1.5">
                          <path
                            strokeLinecap="round"
                            d="M20.5 6h-17m6 5l.5 5m4.5-5l-.5 5"
                          />
                          <path d="M6.5 6h.11a2 2 0 0 0 1.83-1.32l.034-.103l.097-.291c.083-.249.125-.373.18-.479a1.5 1.5 0 0 1 1.094-.788C9.962 3 10.093 3 10.355 3h3.29c.262 0 .393 0 .51.019a1.5 1.5 0 0 1 1.094.788c.055.106.097.23.18.479l.097.291A2 2 0 0 0 17.5 6" />
                          <path
                            strokeLinecap="round"
                            d="M18.374 15.4c-.177 2.654-.266 3.981-1.131 4.79c-.865.81-2.195.81-4.856.81h-.774c-2.66 0-3.99 0-4.856-.81c-.865-.809-.953-2.136-1.13-4.79l-.46-6.9m13.666 0l-.2 3"
                          />
                        </g>
                      </svg>
                    </button>
                  </> : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderComplete;
